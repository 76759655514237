import { Component, OnInit, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControlName } from '@angular/forms';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AccountService } from '../../account/account.service';
import { NotificationService } from '../../_services/notification.service';
import { PartManagerAtualizarEstoqueService } from './atualizar-estoque.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from '../../_models/globals';
import { HoldingFTP } from '../_models/holdingFtp';
import { UpdateInventoryHolding } from '../_models/updateInventoryHolding';
import { UpdateInventoryColumn } from '../_models/updateInventoryColumn';
import { User } from '../../_models/user';
import { Validators } from 'ngx-editor';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { fileSizeToMB } from 'app/_utils/general-utils';

declare const $: any;
declare const dynamicDownloadByHtmlTag: any;
declare const isEmpty: any;
declare const toBase64: any;

@Component({ selector: 'app-atualizar-estoque', templateUrl: './atualizar-estoque.component.html', styleUrls: ['./atualizar-estoque.component.scss'] })
export class PartManagerAtualizarEstoqueComponent implements OnInit
{
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    uploadFormatAccepted: string;
    uploadMaxSizeAccepted: number;

    configUpload: any;
    currentUser: User;

    base64file: string = '';
    filename: string = '';
    fileSelected: boolean = false;

    coluna: any;
    colunas: string[];
    dados_holding_ftp: HoldingFTP;
    hasFile: boolean;
    hasSelectedColumns: boolean;
    hasListeners: boolean;
    logErro: string;
    is_feedbacking: boolean;
    selected_step: number;
    tem_txt_posicional: boolean = false;
    tipo_resultado: number;
    tipo_peca_padrao: number;
    last_update: Date;

    colunasForm: UntypedFormGroup;
    errors: any[] = [];

    constructor(private accountService: AccountService,
        private notificationService: NotificationService,
        private estoqueService: PartManagerAtualizarEstoqueService,
        private globals: Globals,
        private elRef: ElementRef,
        private fb: UntypedFormBuilder,
        private ngxLoader: NgxUiLoaderService) {
        this.currentUser = this.accountService.obterUsuario();

        this.uploadFormatAccepted = ".csv,.txt,.zip";
        this.uploadMaxSizeAccepted = 4;

        this.configUpload = {
            multiple: false, formatsAllowed: ".csv,.txt,.zip", maxSize: "4",
            replaceTexts: {
                selectFileBtn: this.globals.translate('part-manager.atualizar-estoque.passos.passo-2.botao.sem-arquivo'),
                resetBtn: this.globals.translate('part-manager.atualizar-estoque.passos.passo-2.botao.limpar'),
                uploadBtn: this.globals.translate('part-manager.atualizar-estoque.passos.passo-2.botao.upload'),
                sizeLimit: this.globals.translate('part-manager.atualizar-estoque.passos.passo-2.mensagens.tamanho')
            },
            uploadAPI: { method: 'uploadArquivo' }
        };

        this.tipo_peca_padrao = this.checarPartType(1) ? 1 : (this.checarPartType(2) ? 2 : 4);

        this.coluna = { padrao_habilitado_fixo: this.checarPartType(1) };
        this.colunasForm = this.fb.group({ desconto: [0], descricao: [0], marca: [0], marca_cod: [0], montadora: [0], padrao_habilitado: [false], part_number: [0], preco: [0], quantidade: [0], tipo_peca: [0], tipo_peca_padrao: [this.tipo_peca_padrao] });

        this.colunasForm.patchValue({ padrao_habilitado: this.coluna.padrao_habilitado_fixo });

        this.colunas = [];
        this.hasFile = false;
        this.hasListeners = false;
        this.is_feedbacking = false;
        this.logErro = '';
        this.selected_step = 0;
        this.tipo_resultado = 0;
        this.last_update = null;
    }

    fileTypeForm: UntypedFormGroup;
    isEditable = true;

    ngOnInit(): void {
        this.fileTypeForm = this.fb.group({
            fileType: ['', Validators.required],
        });

        this.carregarTela();
    }

    atualizarColunas_MudarInteiro(c) {
        return !isNaN(c[1]) ? parseInt(c[1]) : 0;
    }

    ajustarDadosHolding(objeto: HoldingFTP, adicionar: boolean) {
        let valor = adicionar ? 1 : -1;

        objeto.brand_index = objeto.brand_index + valor;
        objeto.brand_id_index = objeto.brand_id_index + valor;
        objeto.desconto_index = objeto.has_linear_discount ? -1 : objeto.desconto_index + valor;
        objeto.part_factory_number_index = objeto.part_factory_number_index + valor;
        objeto.part_number_index = objeto.part_number_index + valor;
        objeto.part_type_id_index = objeto.part_type_id_index + valor;
        objeto.peca_index = objeto.peca_index + valor;
        objeto.preco_index = objeto.preco_index + valor;
        objeto.quantidade_index = objeto.quantidade_index + valor;
    }

    async atualizarEstoque() {
        if (!(await this.notificationService.showConfirmationDialog('part-manager.atualizar-estoque.mensagens.alerta')))
            return;

        this.is_feedbacking = true;

        this.ngxLoader.startLoader('loader-principal');

        let holdingFTP: HoldingFTP = {
            id: this.dados_holding_ftp.id,
            holding_id: this.dados_holding_ftp.holding_id,
            has_linear_discount: this.dados_holding_ftp.has_linear_discount,
            desconto_linear: this.dados_holding_ftp.desconto_linear,
            brand_index: parseInt(this.colunasForm.value.montadora),
            brand_id_index: parseInt(this.colunasForm.value.marca),
            desconto_index: parseInt(this.colunasForm.value.desconto),
            tem_txt_posicional: false,
            is_active: true,
            part_factory_number_index: parseInt(this.colunasForm.value.marca_cod),
            part_number_index: parseInt(this.colunasForm.value.part_number),
            peca_index: parseInt(this.colunasForm.value.descricao),
            preco_index: parseInt(this.colunasForm.value.preco),
            quantidade_index: parseInt(this.colunasForm.value.quantidade),
            part_type_id_index: parseInt(this.colunasForm.value.tipo_peca),
            part_type_id: this.colunasForm.value.tipo_peca_padrao,
            password_ftp: this.dados_holding_ftp.password_ftp,
            username_ftp: this.dados_holding_ftp.username_ftp
        };

        this.ajustarDadosHolding(holdingFTP, false);

        this.estoqueService.atualizarEstoque(holdingFTP, this.base64file, this.filename).subscribe({
            next: (response: ApiResponseModel<string>) => {
                this.tipo_resultado = (response && response.success) ? (isEmpty(response.result) ? 1 : 2) : 3;
                this.logErro = response.result;

                this.carregarDadosHolding(0, () => this.inicializarTela(this.tipo_resultado, undefined));
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: (error: any) =>
            {
                console.log(error);

                this.notificationService.showErrorToastr(this.globals.translate('part-manager.atualizar-estoque.mensagens.erro.salvar.corpo'));

                this.tipo_resultado = 3;
                this.logErro = JSON.stringify(error);
                this.carregarDadosHolding(0, () => this.inicializarTela(this.tipo_resultado, undefined));
                this.ngxLoader.stopLoader('loader-principal');
            }
        });
    }

    atualizarEstoque_BaixarLogErro()
    {
        dynamicDownloadByHtmlTag('log_erro.txt', this.logErro);
    }

    atualizarEstoque_Reiniciar()
    {
        this.is_feedbacking = false;
        this.selected_step = 0;
        this.stepper.reset();
    }

    async atualizarEstoqueAlternativo()
    {
        if (!(await this.notificationService.showConfirmationDialog('part-manager.atualizar-estoque.mensagens.alerta')))
            return;

        let holding_ftp_id = this.dados_holding_ftp.id;
        let holding_id = this.dados_holding_ftp.holding_id;
        let part_type_id = this.dados_holding_ftp.part_type_id;

        this.is_feedbacking = true;

        this.ngxLoader.startLoader('loader-principal');

        this.estoqueService.atualizarEstoqueAlternativo(holding_ftp_id, holding_id, part_type_id, this.base64file, this.fileTypeForm.value.fileType).subscribe({
            next: (response: ApiResponseModel<string>) =>
            {
                this.tipo_resultado = (response && response.success) ? (isEmpty(response.result) ? 1 : 2) : 3;
                this.logErro = response.result;

                this.carregarDadosHolding(0, () => this.inicializarTela(this.tipo_resultado, '.txt'));
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error =>
            {
                console.log(error);

                this.notificationService.showErrorToastr(this.globals.translate('part-manager.atualizar-estoque.mensagens.erro.salvar.corpo'));

                this.tipo_resultado = 3;
                this.logErro = JSON.stringify(error);
                this.carregarDadosHolding(0, () => this.inicializarTela(this.tipo_resultado, '.txt'));
                this.ngxLoader.stopLoader('loader-principal');
            }
        });
    }

    carregarDadosHolding(nAttempts: number, callback) {
        this.estoqueService.carregarDadosHolding().subscribe({
            next: (response: ApiResponseModel<UpdateInventoryHolding>) => {
                if (response && response.success) {
                    this.dados_holding_ftp = response.result.holdingFtp;

                    if (!isEmpty(this.dados_holding_ftp))
                    {
                        this.tem_txt_posicional = this.dados_holding_ftp.tem_txt_posicional;

                        if (this.tem_txt_posicional)
                            this.fileTypeForm.value.fileType = '.txt';
                    }

                    this.last_update = response.result.lastUpdate;

                    this.ajustarDadosHolding(this.dados_holding_ftp, true);

                    if (!this.tem_txt_posicional)
                        this.hasListeners = true;

                    if (typeof callback === 'function')
                        callback();
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('part-manager.atualizar-estoque.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarDadosHolding(++nAttempts, callback);
            }
        });
    }

    carregarTela() {
        this.ngxLoader.startLoader('loader-principal');
        this.carregarDadosHolding(0, undefined);
    }

    checarPartType(part_type: number) {
        if (this.currentUser === undefined || this.currentUser.holding_type_id == undefined)
            return false;

        return (part_type === 1 && this.currentUser.holding_type_id === 2) || (part_type > 1 && (this.currentUser.part_type & part_type) === part_type);
    }

    habilitarPreenchimentoPadrao() {
        this.colunasForm.patchValue({ tipo_peca: 0, tipo_peca_padrao: this.tipo_peca_padrao });
    }

    inicializarColunas() {
        this.colunasForm.patchValue({
            padrao_habilitado: this.coluna.padrao_habilitado_fixo,
            tipo_peca_padrao: this.tipo_peca_padrao,

            desconto: this.dados_holding_ftp.desconto_index >= this.colunas.length ? '0' : this.dados_holding_ftp.desconto_index,
            descricao: this.dados_holding_ftp.peca_index >= this.colunas.length ? '0' : this.dados_holding_ftp.peca_index,
            part_number: this.dados_holding_ftp.part_number_index >= this.colunas.length  ? '0' : this.dados_holding_ftp.part_number_index,
            preco: this.dados_holding_ftp.preco_index >= this.colunas.length ? '0' : this.dados_holding_ftp.preco_index,
            quantidade: this.dados_holding_ftp.quantidade_index >= this.colunas.length ? '0' : this.dados_holding_ftp.quantidade_index,

            marca: isEmpty(this.dados_holding_ftp.brand_id_index) || this.dados_holding_ftp.brand_id_index >= this.colunas.length ? '0' : this.dados_holding_ftp.brand_id_index,
            marca_cod: isEmpty(this.dados_holding_ftp.part_factory_number_index) || this.dados_holding_ftp.part_factory_number_index >= this.colunas.length ? '0' : this.dados_holding_ftp.part_factory_number_index,
            montadora: isEmpty(this.dados_holding_ftp.brand_index) || this.dados_holding_ftp.brand_index >= this.colunas.length ? '0' : this.dados_holding_ftp.brand_index,
            tipo_peca: isEmpty(this.dados_holding_ftp.part_type_id_index) || this.dados_holding_ftp.part_type_id_index >= this.colunas.length ? '0' : this.dados_holding_ftp.part_type_id_index
        });
    }

    inicializarTela(tipo_resultado: number, fileType) {
        this.colunas = [];

        this.fileTypeForm.value.fileType = fileType;
        this.hasFile = false;
        this.selected_step = 0;

        this.inicializarColunas();

        this.stepper.reset();
        this.uploadLimpar();

        if (tipo_resultado === 1)
            setTimeout(() => { this.is_feedbacking = false; this.selected_step = 0; }, 4000);
    }

    showPreviousStep() {
        this.stepper.previous();
        this.selected_step--;
    }

    async showNextStep() {
        switch (this.selected_step) {
            case 0:
                if (isEmpty(this.fileTypeForm.value.fileType)) {
                    await this.notificationService.showWarning('part-manager.atualizar-estoque.passos.passo-1.erro.corpo', 'part-manager.atualizar-estoque.passos.passo-1.erro.titulo');
                    return;
                }

                break;
            case 1:
                if (!this.hasFile) {
                    await this.notificationService.showWarning('part-manager.atualizar-estoque.passos.passo-2.erro.corpo', 'part-manager.atualizar-estoque.passos.passo-2.erro.titulo');
                    return;
                }

                this.inicializarColunas();
                break;
            case 2:
                if (!await this.validarFormulario())
                    return;
                break;
        }

        this.stepper.selected.completed = true;
        this.stepper.next();
        this.selected_step++;
    }

    stepChanged(args: StepperSelectionEvent) {
        switch (args.selectedIndex) {
            case 0:
                this.uploadLimpar();
                break;
            case 1:
                this.uploadFormatAccepted = (this.fileTypeForm.value.fileType === ".csv" ? '.csv,.zip' : '.txt,.zip');
                break;
        }
    }

    async selectArquivo() {
        var files = (<any>document.getElementById('fileUpload')).files;

        if (files.length === 0)
            return;

        let file = files[0];
        let fileSizeMB = fileSizeToMB(file);

        if (fileSizeMB > this.uploadMaxSizeAccepted) {
            await this.notificationService.showWarning('part-manager.atualizar-estoque.passos.passo-2.mensagens.erro.tamanho', 'part-manager.atualizar-estoque.passos.passo-2.mensagens.erro.titulo');
            return;
        }

        if (!file.name.endsWith(this.fileTypeForm.value.fileType) && !file.name.endsWith(".zip")) {
            await this.notificationService.showWarning('part-manager.atualizar-estoque.passos.passo-2.mensagens.erro.formato', 'part-manager.atualizar-estoque.passos.passo-2.mensagens.erro.titulo');
            return;
        }

        let b64file = (await toBase64(file));

        this.base64file = b64file.indexOf(',') > -1 ? b64file.split(',')[1] : b64file;
        this.filename = file.name;
        this.fileSelected = true;
    }

    async uploadArquivo($event) {
        this.ngxLoader.startLoader('loader-principal');

        this.estoqueService.obterColunas(this.base64file, this.filename, this.fileTypeForm.value.fileType).subscribe({
            next: (response: ApiResponseModel<UpdateInventoryColumn>) => {
                if (response && response.success) {
                    this.hasFile = true;

                    if (!this.dados_holding_ftp.tem_txt_posicional) {
                        this.colunas = response.result.colunas;
                        this.colunas.unshift(this.globals.translate('elemento.generico-select.selecione'));

                        this.notificationService.showSuccessToastr(this.globals.translate('part-manager.atualizar-estoque.passos.passo-2.mensagens.sucesso.corpo'));
                    } else {
                        this.notificationService.showSuccessToastr(this.globals.translate('part-manager.atualizar-estoque.passos.passo-2-alternativo.mensagens.sucesso.corpo'));
                    }
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: (error: ApiResponseModel<UpdateInventoryColumn>) => {
                console.log(error);

                let msgErro = 'part-manager.atualizar-estoque.passos.passo-2.mensagens.erro.desconhecido';

                if (error && error.success) {
                    msgErro = error.result.status === 1 ? 'part-manager.atualizar-estoque.passos.passo-2.mensagens.erro.upload-erro' :
                            (error.result.status === 2 ? 'part-manager.atualizar-estoque.passos.passo-2.mensagens.erro.extensao-invalida' :
                            'part-manager.atualizar-estoque.passos.passo-2.mensagens.erro.desconhecido');

                    this.uploadLimpar();
                }

                this.notificationService.showErrorToastr(this.globals.translate(msgErro));
                this.ngxLoader.stopLoader('loader-principal');
            }
        });
    }

    uploadLimpar() {
        this.hasFile = false;
        this.fileSelected = false;
        this.filename = null;
        this.base64file = null;
    }

    async validarFormulario() {
        let formulario = this.colunasForm.value;
        this.hasSelectedColumns = false;

        // Valida se tem campo não preenchido
        if (formulario.part_number <= 0 || formulario.preco <= 0
            || (formulario.desconto <= 0 && !this.dados_holding_ftp.has_linear_discount)
            || formulario.quantidade <= 0 || (formulario.tipo_peca <= 0 && !formulario.padrao_habilitado)
        ) {
            await this.notificationService.showWarning('part-manager.atualizar-estoque.passos.passo-3.erro.obrigatorio.corpo', 'part-manager.atualizar-estoque.passos.passo-3.erro.obrigatorio.titulo');
            return this.hasSelectedColumns;
        }

        // Valida se tem coluna repetida
        let findDuplicates = arr => arr.filter((item, index) => item > 0 && item !== null && !isNaN(item) && arr.indexOf(item) !== index);

        let propriedadesObj = Object.getOwnPropertyNames(formulario).filter(prop => prop !== 'padrao_habilitado' && prop !== 'tipo_peca_padrao');
        let valoresObj = arr => arr.map((item) => parseInt(formulario[item]));

        if ((findDuplicates(valoresObj(propriedadesObj))).length > 0)
        {
            await this.notificationService.showWarning('part-manager.atualizar-estoque.passos.passo-3.erro.repetido.corpo', 'part-manager.atualizar-estoque.passos.passo-3.erro.repetido.titulo');
            return this.hasSelectedColumns;
        }

        this.hasSelectedColumns = true;
        return this.hasSelectedColumns;
    }
}
