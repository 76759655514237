import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Globals } from 'app/_models/globals';
import { forkJoin } from 'rxjs';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { CustomComission, CustomComissionDisplay } from '../../_models/customComission';
import { RepairerOptions } from '../../_models/customComissionRepairer';
import { HoldingSupplier } from '../../_models/holdingSupplier';

import { NotificationService } from 'app/_services/notification.service';
import { SupportHoldingsBillingDataManagementService } from '../../billing-data-management.service';

declare const sort_by: any;

@Component({ selector: 'app-holding-custom-comission', templateUrl: './holding-custom-comission.component.html', styleUrls: ['./holding-custom-comission.component.scss'] })
export class SupportHoldingCustomComissionComponent {
    @ViewChild('templateHoldingCustomComission') template: TemplateRef<any>;
    @Input() holding: HoldingSupplier;
    @Input() defaultComission: number;
    hasChanged: boolean = false;

    repairers: RepairerOptions[] = [];
    customComissions: CustomComissionDisplay[] = [];

    modalRef: BsModalRef;
    editionForm: UntypedFormGroup;

    constructor(private holdingService: SupportHoldingsBillingDataManagementService, private notificationService: NotificationService, private ngxLoader: NgxUiLoaderService, private modalService: BsModalService, private formBuilder: UntypedFormBuilder, private globals: Globals) {}

    addRepairerComission() {
        const formValue = this.editionForm.value;

        if (formValue.repairer == "") {
            this.editionForm.controls.repairer.markAsTouched();
            this.notificationService.showErrorToastr('support.manage-holdings.manage.comission-manage.form.error.repairer', { translateMessage: true });
            return;
        }

        if (this.holding.opportunityCost == formValue.comission) {
            this.editionForm.controls.comission.markAsTouched();
            this.notificationService.showErrorToastr('support.manage-holdings.manage.comission-manage.form.error.custom-comission-equal', { translateMessage: true });
            return;
        }

        this.ngxLoader.startLoader('loader-repairers-comission');

        this.holdingService.saveCustomComission(this.holding.holdingId, formValue.repairer, formValue.comission).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response.result) {
                    let index = this.repairers.findIndex(r => r.repairerHoldingId == formValue.repairer);
                    let repairer = this.repairers[index]
                    repairer.isBlocked = true;

                    let customComission: CustomComissionDisplay = { repairerHoldingId: repairer.repairerHoldingId, repairerName: repairer.repairerName, comissionValue: formValue.comission }
                    this.customComissions.push(customComission);
                    this.customComissions.sort(sort_by([{ name: 'repairerName' }]));

                    this.resetForm();

                    this.ngxLoader.stopLoader('loader-repairers-comission');
                    this.notificationService.showSuccessToastr('support.manage-holdings.manage.comission-manage.messages.add.success', { translateMessage: true });
                }
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-repairers-comission');
                this.notificationService.showErrorToastr('support.manage-holdings.manage.comission-manage.messages.add.error', { translateMessage: true });
            }
        })
    }

    async close(): Promise<void> {
        this.modalRef.hide()
    }

    notEqualToOpportunityCostValidator(control: AbstractControl) {
        if (this.holding && control.value === this.holding.opportunityCost) {
            return { notEqualToOpportunityCost: true };
        }
        return null;
    }

    open() {
        this.resetForm();
        let options: ModalOptions = { class: 'custom-modal', backdrop: 'static', keyboard: false };
        this.modalRef = this.modalService.show(this.template, options);

        if (this.repairers.length > 0)
            return;

        this.ngxLoader.startLoader('loader-repairers-comission');

        forkJoin({
            getSupplierRepairers: this.holdingService.getSupplierRepairers(this.holding.holdingId, this.holding.isBillable),
            getRepairerSupplierComissions: this.holdingService.getSupplierRepairerComissions(this.holding.holdingId),
        }).subscribe({
            next: (response: {
                getSupplierRepairers: ApiResponseModel<RepairerOptions[]>,
                getRepairerSupplierComissions: ApiResponseModel<CustomComission[]>
            }) => {
                if (response.getSupplierRepairers.result.length == 0) {
                    this.close();
                    this.ngxLoader.stopLoader('loader-repairers-comission');
                    this.notificationService.showWarningToastr('support.manage-holdings.manage.comission-manage.messages.empty.warning', { translateMessage: true });
                }

                this.repairers = response.getSupplierRepairers.result;

                response.getRepairerSupplierComissions.result.forEach((comission: CustomComission) => {
                    let repairerIndex = this.repairers.findIndex(r => r.repairerHoldingId == comission.repairerHoldingId);
                    let repairer = this.repairers[repairerIndex]
                    repairer.isBlocked = true;

                    let customComission: CustomComissionDisplay = { repairerHoldingId: repairer.repairerHoldingId, repairerName: repairer.repairerName, comissionValue: comission.comissionValue }
                    this.customComissions.push(customComission);
                    this.customComissions.sort(sort_by([{ name: 'repairerName' }]));
                })


                this.ngxLoader.stopLoader('loader-repairers-comission');
            },
            error: error => {
                console.log(error);
                this.close();
                this.ngxLoader.stopLoader('loader-repairers-comission');
                this.notificationService.showErrorToastr('support.manage-holdings.manage.comission-manage.messages.loading.error', { translateMessage: true });
            }
        });
    }

    removeRepairerComission(repairerId: number) {
        this.ngxLoader.startLoader('loader-repairers-comission');

        this.holdingService.removeCustomComission(this.holding.holdingId, repairerId).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response.result) {
                    let repairerIndex = this.repairers.findIndex(r => r.repairerHoldingId == repairerId);
                    let repairer = this.repairers[repairerIndex]
                    repairer.isBlocked = false;

                    let repairerComissionsIndex = this.customComissions.findIndex(c => c.repairerHoldingId == repairerId);
                    this.customComissions.splice(repairerComissionsIndex, 1);

                    this.ngxLoader.stopLoader('loader-repairers-comission');
                    this.notificationService.showSuccessToastr('support.manage-holdings.manage.comission-manage.messages.remove.success', { translateMessage: true });
                }
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-repairers-comission');
                this.notificationService.showErrorToastr('support.manage-holdings.manage.comission-manage.messages.remove.error', { translateMessage: true });
            }
        })
    }

    resetForm() {
        this.editionForm = this.formBuilder.group({
            comission: [0, [Validators.required, Validators.min(0), Validators.max(99.99), this.notEqualToOpportunityCostValidator.bind(this)]],
            repairer: ["", Validators.required],
        });
    }
}
