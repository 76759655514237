import { AbstractControl, ValidatorFn, FormArray } from '@angular/forms';

export function ResponderIsUsedValidator(allowsUsed: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const isUsed = control.value;

    if (!allowsUsed && isUsed) {
      return { 'isUsedError': true };
    }

    return null;
  };
}
