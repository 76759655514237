<main class="manage">
    <form [formGroup]="productManageForm" class="form-validate">
        <ng-scrollbar>
            <div class="form-content">
                <div class="left-section">
                    <div class="title">
                        <label for="title">{{ 'seller.commerce.products.manage.label.title' | translate }}</label>
                        <input type="text" [placeholder]="'seller.commerce.products.manage.placeholder.title' | translate" name="title" class="form-control text-capitalize restrict-special-characters" formControlName="title" [ngClass]="{ 'is-invalid': productManageForm.controls.title.invalid && productManageForm.controls.title.touched }">
                    </div>
                    <div class="description">
                        <label for="description">{{ 'seller.commerce.products.manage.label.description' | translate }}</label>
                        <textarea name="description" rows="4" [placeholder]="'seller.commerce.products.manage.placeholder.description' | translate" class="form-control"  formControlName="description"></textarea>
                    </div>
                    <div class="images">
                        <label for="images">{{ 'seller.commerce.products.manage.label.images' | translate }}</label>
                        <div class="images-container">
                            <div class="images">
                                @for (image of images; track image; let i = $index) {
                                    <div class="image-container">
                                        @if (!image.isSelected) {
                                            <div class="image image-selection" (click)="openFileSelection($event)">
                                                <i-feather name="plus"></i-feather>
                                            </div>
                                            <input accept="image/*" type="file" #fileInput (change)="addImage($event, i)">
                                        } @else {
                                            <div class="image image-edition">
                                                @if (i != 0) {
                                                    <div class="image-chevron image-chevron-left" (click)="changeImageOrderToLeft(i)">
                                                        <i-feather name="chevron-left"></i-feather>
                                                    </div>
                                                }
                                                <img [src]="'data:image/jpeg;charset=utf-8;base64,' + images[i].imageFile" [alt]="images[i].imageName">
                                                <div class="trash-container" (click)="deletePicture(i)">
                                                    <i-feather name="trash-2" class="trash"></i-feather>
                                                </div>
                                                @if (i != images.length - 1) {
                                                    <div class="image-chevron image-chevron-right" (click)="changeImageOrderToRight(i)">
                                                        <i-feather name="chevron-right"></i-feather>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div class="input-warning">
                                <i-feather name="info" class="info"></i-feather>
                                <p>{{ 'seller.commerce.products.manage.label.images-info' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-section">
                    <div class="part-number-category">
                        <div class="part-number">
                            <label for="title">{{ 'seller.commerce.products.manage.label.part-number' | translate }}</label>
                            <input type="text" [placeholder]="'seller.commerce.products.manage.placeholder.part-number' | translate" name="title" class="form-control text-uppercase" formControlName="partNumber" [ngClass]="{ 'is-invalid': productManageForm.controls.partNumber.invalid && productManageForm.controls.partNumber.touched }">
                        </div>
                        <div class="category">
                            <label for="categoryId">{{ 'seller.commerce.products.manage.label.categories.title' | translate }}</label>
                            <select #categorySelect class="form-select" formControlName="categoryId" [ngClass]="{ 'is-invalid': productManageForm.controls.categoryId.invalid && productManageForm.controls.categoryId.touched }">
                                <option value="" disabled selected hidden>{{ 'seller.commerce.products.manage.placeholder.category' | translate }}</option>

                                @for (category of categories; track category.categoryId) {
                                    <option [value]="category.categoryId">{{ ('seller.commerce.products.categories.' + category.name) | translate }}</option>
                                }
                            </select>
                        </div>
                    </div>
                    <div class="price">
                        <label>{{ 'seller.commerce.products.manage.label.price.title' | translate }}</label>
                        <div class="input-container">
                            <div class="input-container-form">
                                <div class="total-price">
                                    <label for="total-price">{{ 'seller.commerce.products.manage.label.price.total-price' | translate }}</label>
                                    <div class="input-with-icon currency discount">
                                        <input type="text" class="form-control" name="total-price" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="9999.99" maxlength="6" formControlName="price" [ngClass]="{ 'is-invalid': productManageForm.controls.price.invalid && productManageForm.controls.price.touched }" />
                                    </div>
                                </div>
                                <div class="discount">
                                    <label for="total-price">{{ 'seller.commerce.products.manage.label.price.discount' | translate }}</label>
                                    <div class="input-with-icon percentage discount">
                                        <input type="text" class="form-control" name="discount" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="99" maxlength="4" formControlName="discount"/>
                                    </div>
                                </div>
                            </div>
                            <div class="final-value-container">
                                <p>{{ 'seller.commerce.products.manage.label.price.final-price' | translate }}</p>
                                <p class="final-price">{{finalPrice | currency:'R$' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="price">
                        <label>{{ 'seller.commerce.products.manage.label.cashback.title' | translate }}</label>
                        <div class="input-container">
                            <div class="input-container-form">
                                <div class="total-price">
                                    <label>{{ 'seller.commerce.products.manage.label.cashback.infos.price-with-discount' | translate }}</label>
                                    <div class="final-value-container without-label">
                                        <p class="final-price">{{finalPrice | currency:'R$' }}</p>
                                    </div>
                                </div>
                                <div class="cashback">
                                    <label for="cashback">{{ 'seller.commerce.products.manage.label.cashback.infos.cashback-percentage' | translate }}</label>
                                    <div class="input-with-icon percentage cashback">
                                        <input type="text" class="form-control" name="cashback" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="99" maxlength="4" formControlName="cashback"/>
                                    </div>
                                </div>
                            </div>
                            <div class="final-value-container">
                                <p>{{ 'seller.commerce.products.manage.label.cashback.infos.cashback-final-value' | translate }}</p>
                                <p class="final-cashback">{{cashbackValue | currency:'R$' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="is-active">
                        <div class="input-container switch-variant">
                            <label for="is-active">{{ 'seller.commerce.products.manage.label.is-active' | translate }}</label>
                            <label class="switch switch-lg">
                                <input type="checkbox" formControlName="isActive"/>
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </ng-scrollbar>
        <footer>
            <button customButton styleType="primary" [outline]="true" iconName="x" label="Cancelar" [isLoading]="false" (click)="close()"></button>
            <button customButton styleType="secondary" [iconName]="!isLoading ? 'check' : null" label="Salvar item" [isLoading]="isLoading" (click)="save()"></button>
        </footer>
    </form>
</main>

<ng-template #templateCropperModal>
    <div class="modal-crop-image">
        <div class="modal-header">
            <div class="d-flex flex-column">
                <h4 class="modal-title">{{ 'seller.commerce.products.manage.crop.title' | translate }}</h4>
                <span class="modal-subtitle">
                    <span>{{ 'seller.commerce.products.manage.crop.subtitle' | translate }}</span>
                </span>
            </div>
            <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="closeCropper();"></button>
        </div>
        <div class="modal-body">
            <image-cropper
                #cropper
                [disabled]="imageLoading"
                [autoCrop]="false"
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="1 / 1"
                format="png"
                output="base64"
                (imageCropped)="imageCropped($event)"
            ></image-cropper>
        </div>
        <div class="modal-footer">
            <button customButton styleType="primary" [outline]="true" iconName="x" [label]="'elemento.generico-botao.cancelar' | translate" [isLoading]="false" (click)="closeCropper()"></button>
            <button customButton styleType="secondary" [iconName]="!imageLoading ? 'check' : null" [label]="'seller.commerce.products.manage.crop.confirm' | translate" [isLoading]="imageLoading" (click)="confirm()"></button>
        </div>
    </div>
</ng-template>
