import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, FormControlName, UntypedFormGroup, Validators } from '@angular/forms';

import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxBrazilMASKS, NgxBrazilValidators } from 'ngx-brazil';
import { NgxUiLoaderService } from "ngx-ui-loader";

import { capitalizeFirstLetter } from 'app/_utils/general-utils';

import { AccountService } from "app/account/account.service";
import { BaseService } from 'app/_services/base.service';
import { GeneralService } from 'app/_services/general.service';
import { NotificationService } from "app/_services/notification.service";
import { SupportCotacoesService } from "./cotacoes.service";

import { ApiResponseModel } from "app/_models/apiResponseModel";
import { DisplayMessage, GenericValidator, ValidationMessages } from 'app/_utils/generic-form-validation';
import { EnviarLinkRapido } from "./_models/EnviarLinkRapido";
import { Globals } from 'app/_models/globals';
import { HoldingCompanyUserPostModel, CarregarCotacoesResponseModel } from './_models/carregarCotacoesResponseModel';
import { SuporteCotacao, SuporteCotacaoPeca } from '../_models/suporteCotacao';
import { SendLinkResponseModel } from './_models/sendLinkResponseModel';
import { User } from 'app/_models/user';
import { WhatsAppMessage } from 'app/_models/whatsAppMessage';
import { QuotationPageTypeEnum } from "./_models/quotationPageTypeEnum";
import { FilterOption } from 'app/_componentes/header/_models/filterOption';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';

declare const $: any;
declare const deepSetObj: any;
declare const findInArray: any;
declare const findInArrayIndex: any;
declare const getOnlyNumbers: any;
declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-cotacoes-suporte', templateUrl: './cotacoes.component.html', styleUrls: ['./cotacoes.component.scss'] })
export class SupportCotacoesComponent implements OnInit {
    @ViewChild(NgbAccordionDirective) accordion: NgbAccordionDirective;
    @ViewChild('templateAdicionarVendedorExterno', { static: true }) templateAdicionarVendedorExterno: TemplateRef<any>;
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    capitalizeFirstLetter: any = capitalizeFirstLetter;
    _searchString: string;
    _role: number;

    cotacaoSelecionada: any;
    cotacoes: SuporteCotacao[];
    cotacoesFiltradas: any;
    modalRef: BsModalRef;
    loading: any;
    pecaSelecionada: SuporteCotacaoPeca;
    vendedorExternoPecasSelecionadas: number[] = [];
    vendedores: any;

    vendedorExternoForm: UntypedFormGroup;
    vendedorExternoListaCompanies: HoldingCompanyUserPostModel[];
    vendedorExternoListaHoldings: HoldingCompanyUserPostModel[];
    vendedorExternoListaVendedores: HoldingCompanyUserPostModel[];

    modal_visivel: { alterar_vendedores: boolean, editar_cotacao: boolean, editar_peca: boolean };

    displayMessage: DisplayMessage = {};
    errors: any[] = [];
    genericValidator: GenericValidator;
    validationMessages: ValidationMessages;

    usuarioLocal: User;

    public MASKS: any = NgxBrazilMASKS;

    filterOptions: FilterOption[] = [];

    constructor(
        private accountService: AccountService,
        private baseService: BaseService,
        private generalService: GeneralService,
        private notificationService: NotificationService,
        private globals: Globals,
        private service: SupportCotacoesService,
        private fb: UntypedFormBuilder,
        private modalService: BsModalService,
        private ngxLoader: NgxUiLoaderService) {
        this.usuarioLocal = this.baseService.usuarioLocal;

        if (isEmpty(this.usuarioLocal)) {
            this.accountService.logout();
            return;
        }

        this.cotacoes = [];
        this.modal_visivel = { alterar_vendedores: false, editar_cotacao: false, editar_peca: false };
        this.loading = { cotacoes: false, vendedores: false, vendedor_externo: false };
        this.vendedores = { aguardando: [], participando: [] };

        this.validationMessages = {
            cnpj: { required: this.globals.translate('support.cotacoes.vendedores.adicionar-vendedor-externo.campos-mensagens.cnpj.obrigatorio'), cnpj: this.globals.translate('support.cotacoes.vendedores.adicionar-vendedor-externo.campos-mensagens.cnpj.formato') },
            empresa: { required: this.globals.translate('support.cotacoes.vendedores.adicionar-vendedor-externo.campos-mensagens.empresa.obrigatorio') },
            vendedor: { required: this.globals.translate('support.cotacoes.vendedores.adicionar-vendedor-externo.campos-mensagens.vendedor.obrigatorio') },
            whatsapp: { required: this.globals.translate('support.cotacoes.vendedores.adicionar-vendedor-externo.campos-mensagens.whatsapp.obrigatorio'), telefone: this.globals.translate('support.cotacoes.vendedores.adicionar-vendedor-externo.campos-mensagens.whatsapp.formato') },

            holding: { required: this.globals.translate('support.cotacoes.vendedores.adicionar-vendedor-externo.campos-mensagens.holding.obrigatorio') },
            company: { required: this.globals.translate('support.cotacoes.vendedores.adicionar-vendedor-externo.campos-mensagens.company.obrigatorio') },
            usuario: { required: this.globals.translate('support.cotacoes.vendedores.adicionar-vendedor-externo.campos-mensagens.usuario.obrigatorio') }
        };

        this.vendedorExternoListaCompanies = [];
        this.vendedorExternoListaHoldings = [];
        this.vendedorExternoListaVendedores = [];

        this.genericValidator = new GenericValidator(this.validationMessages);
    }

    ngOnInit() {
        this.role = 3;
        this.carregarCotacoes(0);

        this.vendedorExternoForm = this.fb.group({
            cnpj: ['', [Validators.required, NgxBrazilValidators.cnpj]],
            empresa: ['', Validators.required],
            vendedor: ['', Validators.required],

            holding: [null, Validators.required],
            company: [null, Validators.required],
            usuario: [null, Validators.required],

            whatsapp: ['', [Validators.required, NgxBrazilValidators.phoneNumber]],
            tipoNC: ['nc']
        });

        this.modal_adicionar_vendedor_externo_form_control('tipoNC').valueChanges.subscribe(valor => {
            this.vendedorExternoForm.patchValue({ cnpj: '', empresa: '', vendedor: '', whatsapp: '', holding: null, company: null, usuario: null });
            this.displayMessage = {};

            if (valor === 'nc') {
                this.modal_adicionar_vendedor_externo_form_control('cnpj').setValidators([Validators.required, NgxBrazilValidators.cnpj]);
                this.modal_adicionar_vendedor_externo_form_control('empresa').setValidators([Validators.required]);
                this.modal_adicionar_vendedor_externo_form_control('vendedor').setValidators([Validators.required]);

                this.modal_adicionar_vendedor_externo_form_control('holding').setValidators(null);
                this.modal_adicionar_vendedor_externo_form_control('company').setValidators(null);
                this.modal_adicionar_vendedor_externo_form_control('usuario').setValidators(null);
            }
            else {
                this.vendedorExternoForm.controls.company.disable();
                this.vendedorExternoForm.controls.usuario.disable();

                this.modal_adicionar_vendedor_externo_form_control('cnpj').setValidators(null);
                this.modal_adicionar_vendedor_externo_form_control('empresa').setValidators(null);
                this.modal_adicionar_vendedor_externo_form_control('vendedor').setValidators(null);

                this.modal_adicionar_vendedor_externo_form_control('holding').setValidators([Validators.required]);
                this.modal_adicionar_vendedor_externo_form_control('company').setValidators([Validators.required]);
                this.modal_adicionar_vendedor_externo_form_control('usuario').setValidators([Validators.required]);
            }

            this.modal_adicionar_vendedor_externo_form_control('cnpj').updateValueAndValidity();
            this.modal_adicionar_vendedor_externo_form_control('empresa').updateValueAndValidity();
            this.modal_adicionar_vendedor_externo_form_control('vendedor').updateValueAndValidity();

            this.modal_adicionar_vendedor_externo_form_control('holding').updateValueAndValidity();
            this.modal_adicionar_vendedor_externo_form_control('company').updateValueAndValidity();
            this.modal_adicionar_vendedor_externo_form_control('usuario').updateValueAndValidity();
        });

        this.vendedorExternoForm.patchValue({ tipoNC: 'nc' });

        this.filterOptions = [
            { value: '3', label: this.globals.translate('support.perfil.central') },
            { value: '2', label: this.globals.translate('support.perfil.v8') },
            { value: '1', label: this.globals.translate('support.perfil.popular') }
        ]
    }


    accordionChange(panelId: string) {
        var otherPanel = panelId === 'panelVendedores' ? 'panelCotacao' : 'panelVendedores';
        this.accordion.toggle(otherPanel);
    }

    async acao_excluir($event, quotation_id, nAttempts: number) {
        $event.stopPropagation();

        if (!await this.notificationService.showConfirmationDialog('support.cotacoes.mensagens.confirm.excluir'))
            return;

        this.ngxLoader.startLoader('loader-principal');

        this.service.delete(quotation_id).subscribe({
            next: (response: ApiResponseModel<boolean>) => {

                if (response && response.success) {
                    var index = this.cotacoes.findIndex((c: { quotation_id: any; }) => c.quotation_id === quotation_id);

                    if (!isEmpty(index) && index >= 0) {
                        this.removeItemFromList(index);
                    }

                    this.notificationService.showSuccessToastr(this.globals.translate('support.cotacoes.mensagens.sucesso.excluir.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('support.cotacoes.mensagens.erro.excluir.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }

                this.acao_excluir($event, quotation_id, ++nAttempts);
            }
        });
    }

    async acao_marcar_concluido($event, tipo: QuotationPageTypeEnum, quotation_id: number, seller_company_id?: number) {
        $event.stopPropagation();

        if (seller_company_id === 0)
            return;

        let text = tipo === QuotationPageTypeEnum.Cotacoes ? 'support.cotacoes.colunas.marcar-completo.corpo' : 'support.cotacoes.vendedores.colunas.marcar-completo.corpo';

        if (!await this.notificationService.showConfirmationDialog(text))
            return;

        this.loading.cotacoes = (tipo === QuotationPageTypeEnum.Cotacoes);
        this.loading.vendedores = (tipo === QuotationPageTypeEnum.Vendedores);

        this.acao_marcar_concluido_interno(tipo, quotation_id, 0, seller_company_id);
    }

    private acao_marcar_concluido_interno(tipo: QuotationPageTypeEnum, quotationId: number, nAttempts: number, sellerCompanyId?: number) {
        this.service.setIsDone(tipo, quotationId, sellerCompanyId).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response && response.success) {
                    if (tipo === QuotationPageTypeEnum.Cotacoes) {
                        // Apaga a linha da cotação e insere outra
                        var indice = findInArrayIndex(this.cotacoes, 'quotation_id', quotationId);

                        if (indice >= 0) {
                            this.removeItemFromList(indice);
                            this.notificationService.showSuccessToastr(this.globals.translate('support.cotacoes.mensagens.sucesso.marcar-concluido-cotacao.corpo'));
                        }

                        this.loading.cotacoes = false;
                    } else {
                        // Apaga a linha do vendedor
                        var indice = findInArrayIndex(this.cotacaoSelecionada.vendedores, 'user_company_id', sellerCompanyId);

                        if (indice >= 0) {
                            this.cotacaoSelecionada.vendedores.splice(indice, 1);
                            this.atualizarDadosCotacaoSelecionada();
                        }

                        this.notificationService.showSuccessToastr(this.globals.translate('support.cotacoes.mensagens.sucesso.marcar-concluido-vendedor.corpo'));
                        this.loading.vendedores = false;
                    }
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.loading.cotacoes = false;
                    this.loading.vendedores = false;
                    this.notificationService.showErrorToastr(this.globals.translate('support.cotacoes.mensagens.erro.marcar-concluido.corpo'));
                    return;
                }

                this.acao_marcar_concluido_interno(tipo, quotationId, ++nAttempts, sellerCompanyId);
            }
        });
    }

    atualizarCotacao($event = null, nAttempts: number = 0, callback = null) {
        if ($event != null && typeof $event.stopPropagation === 'function')
            $event.stopPropagation();

        this.loading.vendedores = true;

        const quotation_id = this.cotacaoSelecionada.quotation_id;

        this.service.get(quotation_id).subscribe({
            next: (response: ApiResponseModel<{ cotacao: SuporteCotacao }>) => {
                if (!isEmpty(response) && response.success) {
                    this.cotacaoSelecionada = response.result.cotacao;
                    this.atualizarDadosCotacaoSelecionada();

                    const index = this.cotacoes.findIndex((c: { quotation_id: any; }) => c.quotation_id === quotation_id);

                    if (index >= 0)
                        deepSetObj(this.cotacoes[index], this.cotacaoSelecionada);

                    if (typeof callback === 'function')
                        callback();

                    this.loading.vendedores = false;
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.loading.vendedores = false;
                    this.notificationService.showErrorToastr(this.globals.translate('support.cotacoes.mensagens.erro.atualizar.corpo'));
                    return;
                }

                this.atualizarCotacao($event, ++nAttempts, callback);
            }
        });
    }

    atualizarCotacoes() {
        this.cotacoesFiltradas = this.filtrarCotacoes();
    }

    atualizarDadosCotacaoSelecionada() {
        if (isEmpty(this.cotacaoSelecionada))
            return

        this.filtrarVendedores(this.cotacaoSelecionada.vendedores);
    }

    atualizarValidacao() {
        this.displayMessage = this.genericValidator.processarMensagens(this.vendedorExternoForm);
    }

    carregarCotacoes(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.service.getAllByRole(this.role).subscribe({
            next: (response: ApiResponseModel<CarregarCotacoesResponseModel>) => {
                if (!isEmpty(response) && response.success) {
                    this.cotacoes = response.result.cotacoes;

                    this.atualizarCotacoes();
                    this.trocarCotacaoSelecionada(0);

                    this.vendedorExternoListaHoldings = response.result.holdings;
                    this.vendedorExternoListaCompanies = response.result.companies;
                    this.vendedorExternoListaVendedores = response.result.sellers;
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.cotacoes.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarCotacoes(++nAttempts);
            }
        });
    }

    filtrarCotacoes() {
        if (!this.searchTerm || !this.cotacoes)
            return this.cotacoes;

        let term: string = this.searchTerm.toLowerCase().replace('-', '');

        var result = this.cotacoes.filter(cotacao =>
            cotacao.quotation_id.toString().indexOf(term) !== -1 ||
            (!isEmpty(cotacao.veiculo) && cotacao.veiculo.replace('-', '').toLowerCase().indexOf(term) !== -1) ||
            cotacao.marca.toLowerCase().indexOf(term) !== -1 ||
            cotacao.comprador.empresa.toLowerCase().indexOf(term) !== -1
        );

        return result;
    }

    filtrarVendedores(vendedores) {
        vendedores.map(v => v.nao_quer_participar = (!isEmpty(v) && !isEmpty(v.razao_nao_participar) && v.razao_nao_participar.length > 0));

        this.vendedores.aguardando = vendedores.filter(v => !v.participando);
        this.vendedores.aguardando.sort(sort_by([{ name: 'has_cashback', reverse: true }, { name: 'is_billable', reverse: true }, { name: 'nome' }]));
        this.vendedores.participando = vendedores.filter(v => v.participando);
        this.vendedores.participando.sort(sort_by([{ name: 'nao_quer_participar' }, { name: 'has_cashback', reverse: true }, { name: 'is_billable', reverse: true }, { name: 'nome' }]));

        $('.sellers-scrollable .ng-scroll-viewport').scrollTop(0);
    }

    modal_adicionar_vendedor_externo_abrir($event) {
        $event.stopPropagation();

        let options: ModalOptions = { backdrop: 'static', class: 'gray modal-lg', keyboard: false };
        this.modalRef = this.modalService.show(this.templateAdicionarVendedorExterno, options);
    }

    async modal_adicionar_vendedor_externo_enviar_link(nAttempts: number, ignorarConfirm: boolean, $event) {
        $event.stopPropagation();

        let idsPecas = this.vendedorExternoPecasSelecionadas;
        let whatsapp = getOnlyNumbers(this.vendedorExternoForm.value.whatsapp);

        if (idsPecas.length <= 0) {
            this.errors.push(this.globals.translate('support.cotacoes.vendedores.adicionar-vendedor-externo.erro-cadastro.peca-faltante'));
            return;
        }

        // Caso o vendedor convidado já tiver sido convidado antes e já tenha respondido
        let vendedorJaConvidado = this.vendedores.participando.find(v => (this.vendedorExternoForm.value?.company?.id === null || v.user_company_id == this.vendedorExternoForm.value?.company?.id) &&
                                                                    getOnlyNumbers(v.fone2) === whatsapp);
        if (vendedorJaConvidado) {
            this.notificationService.showErrorToastr(this.globals.translate('support.cotacoes.vendedores.adicionar-vendedor-externo.erro-cadastro.vendedor-ja-respondeu.corpo'))
            return;
        }

        // Se o vendedor convidado já tiver sido convidado antes
        let vendedorNaoParticipando = this.vendedores.aguardando.find(v => (this.vendedorExternoForm.value.company === null || v.user_company_id == this.vendedorExternoForm.value.company.id) &&
                                                                      getOnlyNumbers(v.fone2) === whatsapp);

        if (!ignorarConfirm) {
            if (vendedorNaoParticipando) {
                if (!await this.notificationService.showConfirmationDialog('support.cotacoes.vendedores.adicionar-vendedor-externo.confirm-já-recebeu'))
                    return;
            }
            else if (!await this.notificationService.showConfirmationDialog('geral.whatsapp.mensagens.confirm'))
                return;
        }

        this.errors = [];
        this.loading.vendedor_externo = true;

        let cnpj = this.vendedorExternoForm.value.tipoNC === 'nc' ? getOnlyNumbers(this.vendedorExternoForm.value.cnpj) : "";
        let vendedor = this.vendedorExternoForm.value.tipoNC === 'nc' ? capitalizeFirstLetter(this.vendedorExternoForm.value.vendedor) : "";
        let usuario = this.vendedorExternoForm.value.tipoNC === 'nc' ? { id: 0 } : this.vendedorExternoForm.value.usuario;
        let linkRapido: EnviarLinkRapido = {
            quotation_id: this.cotacaoSelecionada.quotation_id,
            cnpj: cnpj,
            empresa: this.vendedorExternoForm.value.empresa,
            vendedor: vendedor,
            whatsapp: whatsapp,
            seller_company_id: usuario.id,
            ids_pecas: idsPecas
        };

        this.service.sendLink(linkRapido).subscribe({
            next: (response: ApiResponseModel<SendLinkResponseModel>) => {
                if (!isEmpty(response) && response.success) {
                    let urlPartsfy = this.generalService.getPartsfyUrl();

                    if (response.result.externalSeller) {
                        let urlCotacao = urlPartsfy + '/pedido/' + response.result.token;
                        let whatsappMessage: WhatsAppMessage = { is_from_support: true, urlPartsfy: urlPartsfy, urlCTA: urlCotacao, vendedor_nome: vendedor.replace(/ .*/, ''), vendedor_whatsapp: whatsapp };

                        this.generalService.whatsapp_enviar_link_rapido_vendedor_externo(whatsappMessage, 0);
                    } else {
                        let urlCotacao = urlPartsfy + '/vendedor/visao-geral/responder/' + this.cotacaoSelecionada.quotation_id;
                        let whatsappMessage: WhatsAppMessage = { is_from_support: true, urlPartsfy: urlPartsfy, urlCTA: urlCotacao, vendedor_nome: usuario.nome.replace(/ .*/, ''), vendedor_whatsapp: whatsapp };

                        this.generalService.whatsapp_enviar_link_rapido_vendedor_interno(whatsappMessage, 0);
                    }

                    this.atualizarCotacao($event, 0, null);
                } else {
                    this.notificationService.showWarning('support.cotacoes.mensagens.erro.envio-link-repetido.corpo');
                }

                this.loading.vendedor_externo = false;
                this.modal_adicionar_vendedor_externo_fechar();
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.loading.vendedor_externo = false;
                    this.notificationService.showErrorToastr(this.globals.translate('support.cotacoes.mensagens.erro.envio-link.corpo'));
                    return;
                }

                this.modal_adicionar_vendedor_externo_enviar_link(++nAttempts, true, $event);
            }
        });
    }

    modal_adicionar_vendedor_externo_fechar() {
        this.vendedorExternoForm.patchValue({ tipoNC: 'nc', cnpj: '', empresa: '', vendedor: '', whatsapp: '', holding: null, company: null, usuario: null });
        this.vendedorExternoPecasSelecionadas = [];
        this.modalRef.hide();
    }

    modal_adicionar_vendedor_externo_form_control(nome: string) {
        return this.vendedorExternoForm.get(nome) as UntypedFormControl;
    }

    modal_adicionar_vendedor_externo_selecionar(peca, $event) {
        if ($($event.target).is(":checked"))
            this.vendedorExternoPecasSelecionadas.push(peca.item_id);
        else
            this.vendedorExternoPecasSelecionadas = this.vendedorExternoPecasSelecionadas.filter(p => p != peca.item_id)

        if (this.vendedorExternoPecasSelecionadas.length === 0)
            $('.todas_pecas').prop("checked", false);
        else if (this.vendedorExternoPecasSelecionadas.length === this.cotacaoSelecionada.pecas_todas.length)
            $('.todas_pecas').prop("checked", true);
    }

    modal_adicionar_vendedor_externo_selecionar_todas_pecas($event) {
        let is_checked = $($event.target).is(":checked");

        this.cotacaoSelecionada.pecas_todas.forEach(peca => {
            this.modal_adicionar_vendedor_externo_selecionar(peca, $event);
        });

        $('.peca_notificacao').prop("checked", is_checked);
    }

    modal_adicionar_vendedor_externo_selecionar_cadastrado_holding() {
        if (isEmpty(this.vendedorExternoForm.value.holding)) {
            this.vendedorExternoForm.patchValue({ company: '', usuario: '', whatsapp: '' });
            this.vendedorExternoForm.controls.company.disable();
            this.vendedorExternoForm.controls.usuario.disable();
            return;
        }

        this.vendedorExternoForm.controls.company.enable();

        if (this.modal_adicionar_vendedor_externo_companies.length === 1) {
            this.vendedorExternoForm.patchValue({ company: this.modal_adicionar_vendedor_externo_companies[0] });
            this.vendedorExternoForm.controls.usuario.enable();
        }
    }

    modal_adicionar_vendedor_externo_selecionar_cadastrado_company() {
        if (isEmpty(this.vendedorExternoForm.value.company)) {
            this.vendedorExternoForm.patchValue({ usuario: '', whatsapp: '' });
            this.vendedorExternoForm.controls.usuario.disable();
            return;
        }

        this.vendedorExternoForm.controls.usuario.enable();

        if (this.modal_adicionar_vendedor_externo_vendedores.length === 1)
            this.vendedorExternoForm.patchValue({ usuario: this.modal_adicionar_vendedor_externo_vendedores[0] });
    }

    modal_adicionar_vendedor_externo_selecionar_cadastrado_vendedor() {
        if (isEmpty(this.vendedorExternoForm.value.usuario))
            this.vendedorExternoForm.patchValue({ whatsapp: '' });
        else {
            let usuario = this.vendedorExternoForm.value.usuario;

            if (isEmpty(usuario))
                this.vendedorExternoForm.patchValue({ whatsapp: '' });
            else
                this.vendedorExternoForm.patchValue({ whatsapp: usuario.whatsapp });
        }
    }

    modal_alterar_vendedores_abrir($event) {
        $event.stopPropagation();
        this.modal_visivel.alterar_vendedores = true;
    }

    modal_alterar_vendedores_fechar($event) {
        this.modal_visivel.alterar_vendedores = false;

        if ($event.atualizar)
            this.atualizarCotacao($event, 0, null);
    }

    modal_editar_cotacao_abrir($event) {
        $event.stopPropagation();

        this.modal_visivel.editar_cotacao = true;
    }

    modal_editar_cotacao_fechar($event) {
        this.modal_visivel.editar_cotacao = false;

        setTimeout(() => {
            if ($event.atualizar) {
                let cotacao = this.cotacoes.find(c => c.quotation_id === this.cotacaoSelecionada.quotation_id);

                if (!isEmpty(cotacao)) {
                    this.atualizarCotacao(this.cotacaoSelecionada.quotation_id, 0, () => { cotacao.veiculo = this.cotacaoSelecionada.veiculo; cotacao.marca = this.cotacaoSelecionada.marca; });
                }
            }
        }, 200);
    }

    modal_editar_peca_abrir($event, peca: SuporteCotacaoPeca) {
        $event.stopPropagation();

        this.pecaSelecionada = peca;
        this.modal_visivel.editar_peca = true;
    }

    modal_editar_peca_fechar($event) {
        this.modal_visivel.editar_peca = false;

        setTimeout(() => {
            if ($event.atualizar)
                this.atualizarCotacao()
        }, 200);
    }

    removeItemFromList(index: number) {
        if (this.cotacaoSelecionada.quotation_id === this.cotacoes[index].quotation_id) {
            const filterIsApplied = this.cotacoesFiltradas?.length > 0 && this.cotacoesFiltradas?.length < this.cotacoes.length;
            let nextIndex = this.cotacoes.length - 1 == index ? index - 1 : index + 1;

            if (filterIsApplied) {
                const quotationId = this.cotacoes[index].quotation_id;
                const indexBasedOnFilteredList = findInArrayIndex(this.cotacoesFiltradas, 'quotation_id', quotationId);

                nextIndex = this.cotacoesFiltradas.length - 1 == indexBasedOnFilteredList ? indexBasedOnFilteredList - 1 : indexBasedOnFilteredList + 1;
                this.trocarCotacaoSelecionada(nextIndex);

                this.cotacoesFiltradas.splice(indexBasedOnFilteredList, 1);
                this.cotacoes.splice(index, 1);
                return;
            }

            this.trocarCotacaoSelecionada(nextIndex);
        }

        this.cotacoes.splice(index, 1);
    }

    selecionarCotacao($event, quotation_id) {
        if (!isEmpty($event))
            $event.stopPropagation();

        var cotacao = findInArray(this.cotacoes, 'quotation_id', quotation_id);
        this.cotacaoSelecionada = cotacao;

        this.accordion.expand('panelVendedores');

        this.atualizarDadosCotacaoSelecionada();
    }

    trocarCotacaoSelecionada(indice: number) {
        this.cotacaoSelecionada = indice >= 0 && this.cotacoesFiltradas && this.cotacoesFiltradas.length > indice ? this.cotacoesFiltradas[indice] : undefined;

        if (!isEmpty(this.cotacaoSelecionada))
            this.selecionarCotacao(null, this.cotacaoSelecionada.quotation_id);
        else {
            this.vendedores = { aguardando: [], participando: [] };
            this.cotacaoSelecionada = undefined;
        }

        if(indice == 0)
            $('.quotations-scrollable .ng-scroll-viewport').scrollTop(0);
            $('.quotations-scrollable .ng-scroll-viewport').scrollTop(0);
    }

    whatsapp_enviar(vendedor) {
        //if (!confirm(this.globals.translate('geral.whatsapp.mensagens.confirm')))
        //    return;
        let urlPartsfy = this.generalService.getPartsfyUrl();
        let urlCotacao = urlPartsfy + '/vendedor/visao-geral/responder/' + this.cotacaoSelecionada.quotation_id;
        let oficina = this.cotacaoSelecionada.comprador.empresa;
        let veiculo = this.cotacaoSelecionada.marca + ' - ' + this.cotacaoSelecionada.veiculo;
        let chassi = this.cotacaoSelecionada.chassi;
        let whatsapp = vendedor.fone2.replace(/\D/g, '');
        let vendedor_email = vendedor.email;
        let vendedor_id = vendedor.seller_id;

        let whatsappMessage: WhatsAppMessage = { is_from_support: true, chassi: chassi, oficina: oficina, urlPartsfy: urlPartsfy, urlCTA: urlCotacao, veiculo: veiculo, vendedor_whatsapp: whatsapp, vendedor_email, vendedor_id };

        this.generalService.whatsapp_enviar_responder(whatsappMessage, this.cotacaoSelecionada.quotation_id);
    }

    get QuotationPageTypeEnum(): typeof QuotationPageTypeEnum {
        return QuotationPageTypeEnum;
    }

    get searchTerm(): string {
        return this._searchString;
    }

    set searchTerm(value: string) {
        this._searchString = value;
        this.atualizarCotacoes();
    }

    get role(): number {
        return this._role;
    }

    set role(value: number) {
        this._role = value;

        this.carregarCotacoes(0);
    }

    get modal_adicionar_vendedor_externo_companies() {
        if (this.modal_adicionar_vendedor_externo_holdings.length === 0 || isEmpty(this.vendedorExternoForm.value.holding))
            return [];

        return this.vendedorExternoListaCompanies.filter(c => c.parent_id == this.vendedorExternoForm.value.holding.id);
    }

    get modal_adicionar_vendedor_externo_holdings() {
        return this.vendedorExternoListaHoldings.filter(h => h.marcas.some(mid => mid === this.cotacaoSelecionada.brand_id) && !this.vendedores.aguardando.some(v => v.holding_id === h.id) && !this.vendedores.participando.some(v => v.holding_id === h.id));
    }

    get modal_adicionar_vendedor_externo_vendedores() {
        if (this.modal_adicionar_vendedor_externo_companies.length === 0 || isEmpty(this.vendedorExternoForm.value.company))
            return [];

        return this.vendedorExternoListaVendedores.filter(v => v.parent_id == this.vendedorExternoForm.value.company.id);
    }
}
