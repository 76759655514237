import { HoldingPaymentType } from 'app/_models/holdingPaymentType';

export interface HoldingSupplierForEdit {
    holdingId: number;
    hasCashback: boolean;
    monthlyFee: number;
    opportunityCost?: number;
    paymentType: number;
    plan: number;
    rebate: number;
    tax: number;
}

export function holdingSupplierForEditFactory(
    holdingId: number,
    hasCashback: boolean,
    monthlyFee: number,
    opportunityCost: number,
    paymentType: number,
    plan: number,
    rebate: number,
    tax: number
): HoldingSupplierForEdit {
    return {
        holdingId,
        hasCashback,
        monthlyFee,
        opportunityCost: paymentType === HoldingPaymentType.Order ? null : opportunityCost,
        paymentType,
        plan,
        rebate,
        tax
    }
};
