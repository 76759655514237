<app-header
    [brandIconName]="responderCompService.answerQuotation?.brand"
    [title]="responderCompService.answerQuotation?.vehicle_formatted"
    (onGoBack)="responderCompService.voltar(null)"
>
    <input type="button" class="btn btn-outline-danger nao-participar" value="{{ 'seller.dashboard.responder.botao.nao-quero' | translate}}" (click)="responderCompService.naoParticipar(null)" />
</app-header>

<div class="col-md-12 area-responder {{ accountService.obterCssInIframe() }}">
    <ng-scrollbar height="auto" class="{{ accountService.obterCssInIframe() }}">
        @if (responderCompService.canShowForm) {
            <form name="formResposta" id="formResposta" class="form-resposta form-validate mb-lg" [formGroup]="responderCompService.responderForm" novalidate ngxUiLoaderBlurred [loaderId]="'loader-principal'">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-primary orcamento-solicitado">
                            <div class="card-header painelcabecalho">
                                <b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.solicitado' | translate}}</b>
                                <span class="namecompr">{{responderCompService.answerQuotation.buyer_name}} ({{responderCompService.answerQuotation.repairer_name}})</span>
                                <span class="text-danger text-bold alerta-conferencia"><i class="fa fa-exclamation-triangle"></i> {{ 'seller.dashboard.detalhes.informacoes.cabecalho.alerta-conferencia' | translate}}</span>
                                <small><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.cod-orcamento' | translate}}:</b>    #{{responderCompService.answerQuotation.quotation_id}}</small>
                            </div>
                            <div class="card-body font12">
                                <div class="dados-cotacao col-md-12">
                                    <div class="col-md-6">
                                        <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.cnpj' | translate}}:</b> {{responderCompService.answerQuotation.repairer_cnpj_formatado}}</span>
                                        <br />
                                        <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.telefone' | translate}}:</b> {{responderCompService.answerQuotation.buyer_phone_radio}}</span>
                                        <br />
                                        <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.endereco' | translate}}:</b> {{responderCompService.answerQuotation.repairer_address}}</span>
                                    </div>
                                    <div class="col-md-6">
                                        <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.chassi' | translate}}:</b> {{responderCompService.answerQuotation.chassi}}</span>
                                        <br />
                                        @if (responderCompService.answerQuotation?.observacoes_comprador?.length > 0) {
                                            <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.observacao-comprador' | translate}}:</b> {{responderCompService.answerQuotation.observacoes_comprador}}</span>
                                        }
                                    </div>
                                </div>
                                <div class="table-responsive col-md-12 lista-pecas">
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>{{ 'seller.dashboard.responder.tabela.quantidade' | translate }}</th>
                                                <th>{{ 'seller.dashboard.responder.tabela.peca' | translate }}</th>
                                                <th tooltip="{{ 'seller.dashboard.responder.tabela.codigo.tooltip' | translate }}">{{ 'seller.dashboard.responder.tabela.codigo.label' | translate }}</th>
                                                <th>{{ 'seller.dashboard.responder.tabela.quantidade-em-estoque' | translate }}</th>
                                                <th>{{ 'seller.dashboard.responder.tabela.valor' | translate }}</th>
                                                <th>{{ 'seller.dashboard.responder.tabela.desconto' | translate }}</th>
                                                <th>{{ 'seller.dashboard.responder.tabela.prazo.label' | translate }} <i class="fa fa-info-circle" tooltip="{{ 'seller.dashboard.responder.tabela.prazo.tooltip' | translate }}"></i></th>
                                                <th>{{ 'seller.dashboard.responder.tabela.tipo-peca' | translate }}</th>
                                                <th>{{ 'seller.dashboard.responder.tabela.marca' | translate }}</th>
                                                <th [hidden]="!responderCompService.showIsUsed">{{ 'seller.dashboard.responder.tabela.usada' | translate }}</th>
                                                <th>{{ 'seller.dashboard.responder.tabela.total' | translate }}</th>
                                                <th tooltip="{{ 'seller.dashboard.responder.tabela.opcao-responder.nao-quero' | translate }}"></th>
                                            </tr>
                                        </thead>
                                        @if (responderCompService.answerQuotation) {
                                            <tbody class="tbody1024">
                                                @for (peca of responderCompService.answerQuotation.pecas; track peca; let i = $index) {
                                                    <tr formGroupName="_{{peca.item_id}}">
                                                        <td>{{peca.quantidade}}</td>
                                                        <td class="ellipsis4 truncate-column" [tooltip]="peca.name">{{peca.name}}</td>
                                                        <td class="input-group input-group-sm">
                                                            <input type="text" placeholder="{{peca.code}}" maxlength="50" [(ngModel)]="peca.new_part_code" [readonly]="!peca.block_edit ? true : null" formControlName="peca_new_part_code" class="form-control form-control-sm inputVend" />
                                                            @if (!peca.block_edit && (peca.new_part_code || peca.code)) {
                                                                <span class="input-group-text form-control-sm cursor-pointer small-addon" (click)="copiarClipboard(peca)">
                                                                    <em class="fa fa-copy"></em>
                                                                </span>
                                                            }
                                                            <span class="input-group-text form-control-sm cursor-pointer small-addon" (click)="getPartDetails(peca, i)">
                                                                @if (peca.block_edit) {
                                                                    <em class="fa fa-save"></em>
                                                                } @else {
                                                                    <em class="fa fa-pencil"></em>
                                                                }
                                                            </span>
                                                        </td>
                                                        <td colspan="8" [hidden]="peca.respondida">
                                                            <a href="javascript:void(0)" (click)="peca.respondida = true">{{ 'seller.dashboard.responder.tabela.opcao-responder.quero' | translate}}</a>
                                                        </td>
                                                        <td [hidden]="!peca.respondida">
                                                            <input type="number" class="form-control form-control-sm inputVend" formControlName="peca_quantity_in_stock" [(ngModel)]="peca.quantity_in_stock" min="0" max={{peca.quantidade}} mask="999" (change)="validateQuantityInStock(peca)"/>
                                                        </td>
                                                        <td [hidden]="!peca.respondida">
                                                            <input type="text" class="form-control form-control-sm inputVend" formControlName="peca_price" [(ngModel)]="peca.valor_unitario" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="99999.99" maxlength="7" />
                                                        </td>
                                                        <td [hidden]="!peca.respondida">
                                                            <input type="text" class="form-control form-control-sm inputVend" formControlName="peca_discount" [(ngModel)]="peca.desconto" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="99" maxlength="4" />
                                                        </td>
                                                        <td [hidden]="!peca.respondida">
                                                            <select class="form-select form-select-sm inputVend" formControlName="peca_delivery_time" [(ngModel)]="peca.delivery_time">
                                                                @for (prazo of responderCompService.prazosEntrega; track prazo) {
                                                                    <option [value]="prazo.delivery_time_id">{{prazo?.prazo_formatado_com_tipo}}</option>
                                                                }
                                                            </select>
                                                        </td>
                                                        <td [hidden]="!peca.respondida">
                                                            <select class="form-select form-select-sm inputVend" formControlName="peca_part_type_selected" [(ngModel)]="peca.part_type_selected" (change)="responderCompService.atualizarTipoPeca(peca)">
                                                                @for (partType of peca.part_type_availables; track partType) {
                                                                    <option [value]="partType.id">{{ 'elemento.tipo-pecas.' + partType.display | translate}}</option>
                                                                }
                                                            </select>
                                                        </td>
                                                        <td [hidden]="!peca.respondida">
                                                            <input type="text" [readonly]="peca.desabilitar_marca ? 'bled' : null" [(ngModel)]="peca.brand" maxlength="30" class="form-control form-control-sm inputVend" formControlName="peca_brand" />
                                                        </td>
                                                        <td [hidden]="!peca.respondida || !responderCompService.showIsUsed">
                                                            <div class="form-check c-checkbox needsclick checkboxcot" [ngClass]="{ 'disabled': !peca.allows_used }">
                                                                <label class="needsclick">
                                                                    <input type="checkbox" value="" class="needsclick" [disabled]="true" [(ngModel)]="peca.is_used" formControlName="peca_is_used"/>
                                                                    <span class="fa fa-check"></span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td class="text-end" [hidden]="!peca.respondida">{{sellerService.obterTotalPeca(peca) | currency:' '}}</td>
                                                        <td [hidden]="!peca.respondida" (click)="peca.respondida = false">
                                                            <i class="fa fa-1x fa-close text-danger cursor-pointer"></i>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        }
                                    </table>
                                </div>
                            </div>
                            <div class="card-footer text-end">
                                <span><b>{{ 'seller.dashboard.responder.tabela.rodape.total' | translate}}:</b> {{sellerService.obterTotal(responderCompService.answerQuotation) | currency:'R$ '}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                @if (responderCompService.answerQuotation?.fotos.length > 0) {
                    <div class="row">
                        <div class="col-md-12 fotos">
                            <fotos-pedido [fotos]="responderCompService.answerQuotation?.fotos" [panel_aberto]="true"></fotos-pedido>
                        </div>
                    </div>
                }
                <div class="card card-default col-md-12">
                    @if (!responderCompService.token) {
                        <div class="aviso d-flex flex-wrap">
                            <div class="linha">
                                @if (responderCompService.condicoesFornecimento?.done_configuration) {
                                    <div class="col">
                                        @if (usuarioLogado.is_billable) {
                                            <span>{{ 'seller.dashboard.responder.condicoes-fornecimento.info.pagante' | translate}}</span>
                                        } @else {
                                            <span>{{ 'seller.dashboard.responder.condicoes-fornecimento.info.nao-pagante.p-1' | translate}} <a href="javascript:void(0)" (click)="whatsApp_abrir()">{{ 'seller.dashboard.responder.condicoes-fornecimento.info.nao-pagante.p-2' | translate}}</a> {{ 'seller.dashboard.responder.condicoes-fornecimento.info.nao-pagante.p-3' | translate}}</span>
                                        }
                                        <a class="cursor-pointer" (click)="modal_condicao_fornecimento_abrir()">{{ 'seller.dashboard.responder.condicoes-fornecimento.visualizar' | translate}}</a>
                                    </div>
                                } @else {
                                    <div class="col">
                                        <a class="cursor-pointer" (click)="responderCompService.goToConfigurar()">{{ 'seller.dashboard.responder.condicoes-fornecimento.configurar.titulo' | translate}}</a>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div>
                        @if (!responderCompService.condicoesFornecimento?.done_configuration || responderCompService.condicoesFornecimento?.fretes.length === 0) {
                            <div class="col-md-12 frete">
                                <div class="d-flex flex-row align-items-center flex-wrap mb-3 form-group">
                                    <div class="header">
                                        <label class="col-form-label float-end">{{ 'seller.dashboard.responder.frete-gratuito.label' | translate}}</label>
                                    </div>
                                    <div class="col-md-1">
                                        <select name="frete" class="form-select form-select-sm m-b" formControlName="frete_tipo" [(ngModel)]="responderCompService.answerQuotation?.frete.tipo" (change)="responderCompService.atualizarFrete(responderCompService.answerQuotation?.frete.valor)">
                                            <option value="1">{{ 'elemento.generico-select.sim' | translate}}</option>
                                            <option value="2">{{ 'elemento.generico-select.nao' | translate}}</option>
                                        </select>
                                    </div>
                                    @if (responderCompService.answerQuotation?.frete.tipo==='2') {
                                        <div class="col-md-8 checkbox-group flex">
                                            <input type="text" id="frete_valor" formControlName="frete_valor" [(ngModel)]="responderCompService.answerQuotation?.frete.valor" (change)="responderCompService.atualizarFrete()" [readonly]="(responderCompService.answerQuotation?.frete.nao_entrega || responderCompService.answerQuotation?.frete.conta_comprador) ? 'disabled' : null" placeholder="{{'seller.dashboard.responder.frete-gratuito.digite-aqui' | translate}}" class="form-control form-control-sm col-md-2" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="100000" maxlength="7" />
                                            <div class="col-md-6 form-check c-checkbox needsclick">
                                                <label class="needsclick">
                                                    <input type="checkbox" id="frete_conta_comprador" value="" class="needsclick" [(ngModel)]="responderCompService.answerQuotation?.frete.conta_comprador" (change)="responderCompService.atualizarFrete()" formControlName="frete_conta_comprador" />
                                                    <span class="fa fa-check"></span>{{ 'seller.dashboard.responder.frete-gratuito.conta-comprador' | translate}}
                                                </label>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        @if (responderCompService.condicoesFornecimento?.done_configuration && responderCompService.condicoesFornecimento?.fretes.length > 0) {
                            <div class="col-md-12 frete">
                                <div class="d-flex flex-row align-items-center flex-wrap form-group">
                                    <div class="header">
                                        <label class="col-form-label float-end">{{ 'seller.dashboard.responder.frete.label' | translate}}</label>
                                    </div>
                                    <div class="col-md-7 dados">
                                        {{ responderCompService.freteSelecionadoTexto }} {{ 'seller.dashboard.responder.frete.entrega-regiao' | translate}}
                                    </div>
                                </div>
                            </div>
                        }
                        <div>
                            <div class="col-md-12 faturamento-box">
                                <div class="col-md-12 d-flex flex-row align-items-center flex-wrap faturamento">
                                    <div class="header">
                                        <label class="col-form-label float-end">{{ 'seller.dashboard.responder.faturamento-liberado.label' | translate}}</label>
                                    </div>
                                    <div class="col-md-1">
                                        <select name="faturamento" class="form-select form-select-sm m-b" formControlName="faturamento" [(ngModel)]="responderCompService.answerQuotation.faturamento" (change)="responderCompService.atualizarLimiteFaturamento(responderCompService.answerQuotation?.faturamento_valor, false)" [disabled]="responderCompService.answerQuotation?.subscription_status !== 2 ? true : null">
                                            <option value="1">{{ 'elemento.generico-select.sim' | translate}}</option>
                                            <option value="2" selected>{{ 'elemento.generico-select.nao' | translate}}</option>
                                        </select>
                                    </div>
                                    @if (responderCompService.answerQuotation?.faturamento==='1') {
                                        <div class="col-md-3">
                                            <input type="text" formControlName="faturamento_valor" [(ngModel)]="responderCompService.answerQuotation.faturamento_valor" placeholder="{{'seller.dashboard.responder.faturamento-liberado.digite-aqui' | translate}}" (blur)="responderCompService.atualizarLimiteFaturamento(null, false)" class="form-control form-control-sm" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="100000" maxlength="7" />
                                        </div>
                                    }
                                </div>
                                @if (!responderCompService.condicoesFornecimento?.done_configuration) {
                                    <div class="col-md-12 d-flex flex-row align-items-center flex-wrap forma-pagamento">
                                        <div class="d-flex flex-row align-items-center flex-wrap mb-3 form-group">
                                            <div class="header">
                                                <label class="col-form-label float-end">{{ 'seller.dashboard.responder.forma-pagamento.label' | translate}}?</label>
                                            </div>
                                            <div class="col-md-7 dados">
                                                <select name="selected_payment_type" [attr.disabled]="(responderCompService.answerQuotation?.faturamento !== '1' || responderCompService.formasPagamento.length <= 1) ? 'disabled' : null" [(ngModel)]="responderCompService.answerQuotation.selected_payment_type" class="form-select form-select-sm m-b ddl_tipo_pagamento" formControlName="selected_payment_type">
                                                    @for (tipo of responderCompService.formasPagamento; track tipo) {
                                                        <option [value]="tipo.id">{{tipo.texto_x}}</option>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                } @else {
                                    <div class="col-md-12 d-flex flex-row align-items-center flex-wrap forma-pagamento">
                                        <div class="d-flex flex-row align-items-center flex-wrap mb-3 form-group">
                                            <div class="header">
                                                <label class="col-form-label float-end">{{ 'seller.dashboard.responder.forma-pagamento.label' | translate}}</label>
                                            </div>
                                            <div class="col-md-7 dados" style="white-space: nowrap;">
                                                @if (responderCompService.tiposPagamentoMaiorQueAVista && responderCompService.answerQuotation.faturamento === '1') {
                                                    <span>
                                                        {{ 'seller.dashboard.responder.forma-pagamento.label-em-ate' | translate}}
                                                    </span>
                                                }
                                                {{ (responderCompService.tiposPagamentoMaiorQueAVista && responderCompService.answerQuotation.faturamento === '1') ? responderCompService.tiposPagamento[responderCompService.tiposPagamento.length - 1].paymentType.texto_x : ('seller.dashboard.responder.forma-pagamento.label-a-vista' | translate) }}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            @if (responderCompService.answerQuotation?.subscription_status !== 2) {
                                <div class="col-md-5 faturamento-pendente d-flex flex-row align-items-center flex-wrap">
                                    @if (!area_faturamento) {
                                        <div>
                                            <span>
                                                {{ 'seller.dashboard.responder.faturamento-liberado.nao-liberado.label-1' | translate}}
                                                {{ 'seller.dashboard.responder.faturamento-liberado.nao-liberado.label-2.p1' | translate}} <a href="javascript:void(0)" (click)="area_faturamento = true">{{ 'seller.dashboard.responder.faturamento-liberado.nao-liberado.label-2.p2' | translate}}</a> {{ 'seller.dashboard.responder.faturamento-liberado.nao-liberado.label-2.p3' | translate}}.
                                            </span>
                                            <span>{{ 'seller.dashboard.responder.faturamento-liberado.nao-liberado.label-3' | translate}}</span>
                                        </div>
                                    } @else {
                                        <div class="mb-3 form-group">
                                            <div class="col-md-12 dados">
                                                <small class="col-md-4"><b>{{ 'seller.dashboard.responder.faturamento-liberado.nao-liberado.dados.titulo' | translate}}:</b></small>
                                                <div class="input-group input-group-sm input-group-sm col-md-5">
                                                    <span class="input-group-text form-control-sm">
                                                        <span>R$</span>
                                                    </span>
                                                    <input name="limit" type="text" formControlName="novo_limite" [(ngModel)]="novo_limite" class="form-control form-control-sm zi0" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0.01" max="99999.99" maxlength="7" />
                                                </div>
                                            </div>
                                            <div class="col-md-12 text-center">
                                                <input type="button" class="btn btn-sm btn-primary" value="{{ 'seller.dashboard.responder.faturamento-liberado.nao-liberado.dados.salvar' | translate}}" (click)="saveBuyersLimit(0)" [disabled]="(!novo_limite || novo_limite <= 0) ? 'disabled' : null">
                                                &nbsp;
                                                <input type="button" class="btn btn-sm btn-light" value="{{ 'seller.dashboard.responder.faturamento-liberado.nao-liberado.dados.cancelar' | translate}}" (click)="area_faturamento = false">
                                            </div>
                                        </div>
                                    }
                                    <ngx-loading [show]="loading_limite_credito" [template]="templateLimiteCredito"></ngx-loading>
                                </div>
                            }
                        </div>
                    </div>
                    <div class="col-md-12 notes">
                        <div class="d-flex flex-row flex-wrap mb-3 form-group">
                            <div class="header">
                                <label class="col-form-label float-end">{{ 'seller.dashboard.responder.observacao.label' | translate}}</label>
                            </div>
                            <div class="col-md-9">
                                <textarea class="form-control" rows="2" cols="125" [(ngModel)]="responderCompService.answerQuotation.notes_vendedor" formControlName="notes_vendedor"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 text-center responder">
                        <button type="button" class="btn btn-primary" (click)="responderCompService.participar(0)">{{ 'seller.dashboard.responder.botao.responder' | translate}}</button>
                    </div>
                </div>
            </form>
        }
    </ng-scrollbar>
</div>

<ng-template #templateLimiteCredito></ng-template>

<ng-template #templateConfiguracaoFormaPagamento>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'seller.dashboard.responder.condicoes-fornecimento.modal.titulo' | translate}}</h4>
        <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="modal_condicao_fornecimento_fechar();"></button>
    </div>
    <div class="modal-body">
        <app-condicao-fornecimento [condicoes]="responderCompService.condicoesFornecimento"></app-condicao-fornecimento>
    </div>
    <div class="modal-footer">
        <button id="closeModal" type="button" class="btn btn-light" (click)="modal_condicao_fornecimento_fechar();">{{ 'elemento.generico-botao.fechar' | translate}}</button>
    </div>
</ng-template>
