import { Component, OnInit, AfterViewChecked, ViewChild, TemplateRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { CancelReasonRelatedId, CancelReasons } from 'app/_models/cancelReasons';
import { Globals } from 'app/_models/globals';
import { StatusIdEnum, isFinalStatus } from 'app/_models/statusId';
import { SweetAlertParams } from 'app/_models/notificationParams.model';

import { AccountService } from 'app/account/account.service';
import { BuyerService } from 'app/buyer/_services/buyer.service';
import { GeneralService } from 'app/_services/general.service';
import { NotificationService } from 'app/_services/notification.service';
import { SettingsService } from 'app/_services/settings.service';
import { SellerOrderService } from '../_services/order.service';
import { SellerService } from '../seller.service';

declare const $: any;
declare const isEmpty: any;

enum ModalIds {
    Rejeitar = 0,
    Analise = 1,
    DataEntrega = 2
}

@Component({ selector: 'app-confirmar', templateUrl: './confirmar.component.html', styleUrls: ['./confirmar.component.scss'] })

export class SellerVisaoGeralConfirmarComponent implements OnInit
{
    calendarMaxDate: any;
    calendarMinDate: any;
    calendarMarkDisabled: any;

    isOnRejectMode: boolean = false;
    isOnError: boolean;
    pedido: any;
    order_id: number;
    formasPagamento: any = {};

    motivosAnalise: CancelReasons[] = [];
    motivosRejeitar: CancelReasons[] = [];
    reasonOutros = false;
    motivoSelecionado: boolean = false;
    max_days_delivery: number = 40;
    holidays: string[];
    deliveryDate: any;
    deliveryDateIsEmpty: boolean = false;

    isQuotationWithoutVehicle: boolean = false;

    @ViewChild('templateRejeitarPeca') templateRejeitarPeca: TemplateRef<any>;
    modalRefRejeitar: BsModalRef;

    @ViewChild('templateColocarAnalise') templateColocarAnalise: TemplateRef<any>;
    modalRefColocarAnalise: BsModalRef;

    @ViewChild('templateDataEntrega') templateDataEntrega: TemplateRef<any>;
    modalRefDataEntrega: BsModalRef;

    get StatusIdEnum(): typeof StatusIdEnum {
        return StatusIdEnum;
    }

    hasDevolucaoTroca: boolean = false;
    selectedStatusId: StatusIdEnum;

    // tslint:disable-next-line: max-line-length
    constructor(public accountService: AccountService, private notificationService: NotificationService, private confirmarService: SellerOrderService, public sellerService: SellerService, public settingService: SettingsService, private globals: Globals,
        private route: ActivatedRoute, private carouselConfig: NgbCarouselConfig, private ngxLoader: NgxUiLoaderService, private modalService: BsModalService, private router: Router,
        private generalService: GeneralService, public buyerService: BuyerService, private calendar: NgbCalendar)
    // tslint:disable-next-line: one-line
    {
        this.carouselConfig.interval = 200000;
    }

    ngOnInit()
    {
        this.pedido = {};

        // Obtém o id do pedido passado por parâmetro
        var param_id = this.route.snapshot.params.id;
        var isValid = /^\d+$/.test(param_id);
        this.isOnError = !isValid;

        if (isValid)
        {
            this.order_id = parseInt(param_id.toString());
            this.carregarDetalhes(this.order_id, 0);
        }

        this.getAllHolidays();
        this.getDeliveryDateByWorkingDays();
    }

    ngAfterViewChecked()
    {
        // Caso a tela esteja com erro, volta para a home
        if (this.isOnError)
            this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.sem-codigo', parametro: '' });
    }

    carregarDetalhes(order_id: number, nAttempts: number)
    {
        this.ngxLoader.startLoader('loader-principal');

        this.sellerService.getOrderDetail(order_id).subscribe({
            next: (response: any) => {
                if (response != null) {
                    this.pedido = response.result.pedido;

                    this.hasDevolucaoTroca = this.pedido.pecas.some(p => p.status === StatusIdEnum.AguardandoConfirmacaoDevolucao || p.status === StatusIdEnum.AguardandoConfirmacaoTroca)

                    this.pedido.alguemSelecionado = false;
                    this.pedido.statusEmAnalise = this.pedido.pecas.filter(p => p.status == StatusIdEnum.EmAnalise).length > 0;

                    let formasPagamento = Object.assign([], response.result.formasPagamento);
                    formasPagamento.splice(0, 1);

                    this.formasPagamento = formasPagamento.reduce((acc, item) => ({ ...acc, [item.id]: item.texto }), {});

                    if (this.pedido == null) {
                        this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: order_id });
                        return;
                    }

                    this.isQuotationWithoutVehicle = this.settingService.isQuotationWithoutVehicle(this.pedido.license_plate);
                    this.pedido.fotos = response.result.fotos;
                    this.pedido = this.sellerService.atualizarTotais(this.pedido);

                    let status = this.pedido.pecas.filter(p => !isFinalStatus(p.status))[0]?.status;
                    this.pedido.pecas.map(p => p.status_needs_action = (p.status === StatusIdEnum.AguardandoConfirmacaoDevolucao || p.status === StatusIdEnum.AguardandoConfirmacaoTroca))

                    if (status == null)
                        return;
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                if (error.status === 404) {
                    this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: order_id });
                    return;
                }

                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarDetalhes(order_id, ++nAttempts);
            }
        });
    }

    carregarMotivosRejeitar(order_id: number, nAttempts: number) {
        this.ngxLoader.startLoader('loader-rejeitar');

        this.confirmarService.getOrderReasons(CancelReasonRelatedId.PecaRejeitadaPeloVendedor).subscribe({
            next: (response: any) => {
                this.motivosRejeitar = response.result.cancelReasons;
                this.ngxLoader.stopLoader('loader-rejeitar');
            },
            error: error => {
                if (error.status === 404) {
                    this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: order_id });
                    return;
                }

                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-rejeitar');
                    this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarMotivosRejeitar(order_id, ++nAttempts);
            }
        })
    }

    carregarMotivosAnalise(order_id: number, nAttempts: number) {
        this.ngxLoader.startLoader('loader-analise');

        this.confirmarService.getOrderReasons(CancelReasonRelatedId.PedidoEmAnalise).subscribe({
            next: (response: any) => {
                this.motivosAnalise = response.result.cancelReasons;
                this.ngxLoader.stopLoader('loader-analise');
            },
            error: error => {
                if (error.status === 404) {
                    this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: order_id });
                    return;
                }

                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-analise');
                    this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarMotivosAnalise(order_id, ++nAttempts);
            }
        })
    }

    async colocarEmAnalise(nAttempts: number) {
        var notes = $('textarea[name="comment"]').val().trim();

        if (this.reasonOutros && notes.length === 0) {
            await this.notificationService.showWarning('seller.dashboard.colocar-analise.notes-erro.corpo', 'seller.dashboard.colocar-analise.notes-erro.titulo');
            return;
        }

        this.ngxLoader.startLoader('loader-analise');

        var reason_id = Number($('select[name="reason"]').val().split(' - ')[0]);

        this.confirmarService.setAsInAnalysis(this.order_id, reason_id, notes.length > 0 ? notes : null).subscribe({
            next: () => {
                this.modal_fechar(1)
                this.ngxLoader.stopLoader('loader-analise');
                this.sellerService.voltar(null);
                this.notificationService.showSuccessToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.sucesso.em-analise.corpo'));
            },
            error: error => {
                if (error.status === 404) {
                    this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: this.order_id });
                    return;
                }

                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-analise');
                    this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.erro.em-analise.corpo'));
                    return;
                }

                this.colocarEmAnalise(++nAttempts);
            }
        });
    }

    abrirModalConfirmarDevolucaoTroca() {
        this.deliveryDateIsEmpty = false;
        this.deliveryDate = null;
        this.modal_abrir(2);
    }

    confirmarDevolucaoTroca() {
        if (!isEmpty(this.deliveryDate)) {
            let date = new Date(this.deliveryDate.year, this.deliveryDate.month - 1, this.deliveryDate.day);

            if (date.toString() == 'Invalid Date'){
                this.deliveryDateIsEmpty = true;
                return;
            }

            this.confirmarDevolucaoTrocaExecucao(new Date(date));
            this.modal_fechar(2);
        } else {
            this.deliveryDateIsEmpty = true;
        }
    }

    confirmarDevolucaoTrocaExecucao(selectedDate: Date, nAttempts: number = 0) {
        this.ngxLoader.startLoader('loader-principal');
        var pecas_ids = this.pedido.pecas.filter(p => p.acao && p.status == this.selectedStatusId).map(p => p.item_id);

        this.confirmarService.setAsAwaitingForPickupReplacement(this.order_id, pecas_ids, selectedDate, this.selectedStatusId).subscribe({
            next: () => {
                this.notificationService.showSuccessToastr(this.globals.translate(`seller.dashboard.detalhes.confirmar-devolucao-troca.sucesso.corpo-${this.selectedStatusId == StatusIdEnum.AguardandoConfirmacaoDevolucao ? 'devolucao' : 'troca'}`));
                this.sellerService.voltar(null);
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                if (error.status === 404) {
                    this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: this.order_id });
                    return;
                }

                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.confirmar-devolucao-troca.erro.corpo'));
                    return;
                }

                this.confirmarDevolucaoTrocaExecucao(selectedDate, ++nAttempts);
            }
        })
    }

    async confirmarPedido() {
        if (this.pedido.forma_pagamento_id === 0) {
            let params: SweetAlertParams = {
                icon: 'warning',
                bigSwall: true,
                title: 'seller.dashboard.detalhes.mensagens.confirm.confirmar-pedido-a-vista.titulo',
                input: 'select',
                inputOptions: this.formasPagamento,
                inputPlaceholder: 'seller.dashboard.detalhes.mensagens.confirm.confirmar-pedido-a-vista.placeholder',
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (!isEmpty(value))
                            resolve('');
                        else
                            resolve(this.globals.translate('seller.dashboard.detalhes.mensagens.confirm.confirmar-pedido-a-vista.erro'));
                    })
                },
                showDenyButton: true,
                cancelButtonText: 'elemento.generico-botao.cancelar',
                confirmButtonText: 'seller.dashboard.detalhes.botao.confirmar-pedido-a-vista.faturar',
                denyButtonText: 'seller.dashboard.detalhes.botao.confirmar-pedido-a-vista.manter',
                customClass: { cancelButton: 'order-1', confirmButton: 'order-3', denyButton: 'order-2' }
            }

            await this.notificationService.showConfirm(params).then((result) => {
                if (result.isDismissed)
                    return;

                const new_payment_type_id = result.isDenied ? null : result.value;
                this.confirmarPedidoExecucao(new_payment_type_id, 0);
            });

            return;
        }

        this.confirmarPedidoExecucao(null, 0);
    }

    confirmarPedidoExecucao(new_payment_type_id: number, nAttempts: number)
    {
        this.ngxLoader.startLoader('loader-principal');

        this.confirmarService.setAsAwaitingForDelivery(this.order_id, new_payment_type_id).subscribe({
            next: () => {
                this.notificationService.showSuccessToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.sucesso.confirmar-pedido.corpo'));
                this.sellerService.voltar(null);
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                if (error.status === 404)
                {
                    this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: this.order_id });
                    return;
                }

                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.erro.confirmar-pedido.corpo'));
                    return;
                }

                this.confirmarPedidoExecucao(new_payment_type_id, ++nAttempts);
            }
        });
    }

    imprimir($event)
    {
        $event.preventDefault();
        this.sellerService.imprimir(this, { vehicle: $('.title-section')[0], header: $('.painelcabecalho')[0], subheader: $('.dados-cotacao')[0], footer: $('.card-footer')[0], printView: $('#printView') });
    }

    modal_abrir(id: number): void {
        this.motivoSelecionado = false;
        this.reasonOutros = false;
        $('textarea[name="comment"]').val('');
        $('select[name="reason"]').val([]);
        let options: ModalOptions = { backdrop: 'static', class: 'gray modal-lg', keyboard: false };

        if(id == ModalIds.Rejeitar) { //Rejeitar
            this.carregarMotivosRejeitar(0, this.order_id);
            this.modalRefRejeitar = this.modalService.show(this.templateRejeitarPeca, options);
        } else if(id == ModalIds.Analise) {  //Colocar em análise
            this.carregarMotivosAnalise(0, this.order_id);
            this.modalRefColocarAnalise = this.modalService.show(this.templateColocarAnalise, options);
        } else if(id == ModalIds.DataEntrega) {  //Confirmar data de entrega
            this.modalRefDataEntrega = this.modalService.show(this.templateDataEntrega, { backdrop: 'static', class: 'gray modal-sm', keyboard: false });
        }
    }

    modal_fechar(id: number): void {
        if(id == ModalIds.Rejeitar) //Rejeitar
            this.modalRefRejeitar.hide()
        else if(id == ModalIds.Analise) //Colocar em análise
            this.modalRefColocarAnalise.hide()
        else if(id == ModalIds.DataEntrega) //Confirmar data de entrega
            this.modalRefDataEntrega.hide()
    }

    async rejeitar(nAttempts: number) {
        var notes = $('textarea[name="comment"]').val().trim();

        if(this.reasonOutros && notes.length === 0) {
            await this.notificationService.showWarning('seller.dashboard.rejeitar-peca.notes-erro.corpo', 'seller.dashboard.rejeitar-peca.notes-erro.titulo');
            return;
        }

        if (!(await this.notificationService.showConfirmationDialog('seller.dashboard.detalhes.rejeitar.confirm')))
            return;

        this.ngxLoader.startLoader('loader-rejeitar');

        var pecas_ids = this.pedido.pecas.filter(p => p.acao && p.status <= StatusIdEnum.EmAnalise).map(p => p.item_answer_id);
        var reason_id = Number($('select[name="reason"]').val().split(' - ')[0]);

        this.confirmarService.rejectItens(this.order_id, pecas_ids, reason_id, notes.length > 0 ? notes : null)
            .subscribe({
                next: () => {
                    const status_peca = this.globals.translate('seller.dashboard.detalhes.rejeitar.status');

                    // peça pedido rejeitada pelo vendedor
                    this.pedido.pecas.filter(p => pecas_ids.includes(p.item_answer_id)).map(p => { p.status = StatusIdEnum.PecaRejeitadaoVendedor; p.status_peca = status_peca });

                    this.notificationService.showSuccessToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.sucesso.rejeitar-peca.corpo'));
                    const todasPecasRejeitadas = (this.pedido.pecas.length === this.pedido.pecas.filter(p => isFinalStatus(p.status)).length);

                    this.pedido = this.sellerService.atualizarTotais(this.pedido);

                    this.ngxLoader.stopLoader('loader-rejeitar');
                    this.modal_fechar(0)

                    if (todasPecasRejeitadas)
                        this.sellerService.voltar(null);
                    else
                        this.rejeitarVoltar();
                },
                error: error => {
                    if (error.status === 404) {
                        this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: this.order_id });
                        return;
                    }

                    nAttempts = nAttempts || 1;
                    console.log(error, nAttempts);

                    if (nAttempts >= 5) {
                        this.ngxLoader.stopLoader('loader-rejeitar');
                        this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.erro.rejeitar-peca.corpo'));
                        return;
                    }

                    this.rejeitar(++nAttempts);
                }
            });
    }

    selecionarPeca(peca, $event) {
        peca.acao = $($event.target).is(":checked");
        this.pedido.alguemSelecionado = this.pedido.pecas.filter(p => p.acao && (p.status <= StatusIdEnum.EmAnalise || p.status === StatusIdEnum.AguardandoConfirmacaoDevolucao || p.status === StatusIdEnum.AguardandoConfirmacaoTroca)).length > 0;

        if(!this.pedido.alguemSelecionado)
            this.selectedStatusId = null;
        else if(peca.acao)
            this.selectedStatusId = peca.status;
    }

    rejeitarVoltar()
    {
        this.pedido.pecas.filter(p => p.status <= StatusIdEnum.EmAnalise).map(p => p.acao = false);
        this.isOnRejectMode = false;
    }

    getAllHolidays() {
        this.generalService.getAllHolidays().subscribe({
            next: (response: ApiResponseModel<string[]>) => {
                this.holidays = response.result.map(h => h.replace('T05:00:00', ''));
            }
        });
    }

    getDeliveryDateByWorkingDays() {
        this.generalService.getDeliveryDateByWorkingDays(this.max_days_delivery).subscribe({
            next: (response: ApiResponseModel<{deliveryDate: any}>) => {
                let hoje = new Date();
                let maxDate = new Date(response.result.deliveryDate);
                this.calendarMinDate = { year: hoje.getFullYear(), month: hoje.getMonth() + 1, day: hoje.getDate() };
                this.calendarMaxDate = { year: maxDate.getFullYear(), month: maxDate.getMonth() + 1, day: maxDate.getDate() };
                this.calendarMarkDisabled = (date: NgbDate) => this.buyerService.disableCalendarOnWeekendsAndHolidays(date, this.calendar, this.holidays);

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
            }
        });
    }

    getDynamicTitle() {
        return this.globals.translate(`seller.dashboard.detalhes.confirmar-devolucao-troca.titulo-${this.selectedStatusId == StatusIdEnum.AguardandoConfirmacaoDevolucao ? 'devolucao' : 'troca'}`)
    }

    public updateTextArea($event) {
        this.motivoSelecionado = true;
        $('textarea[name="comment"]').val('');

        if($event.target.value.includes('Reason_Outros')) {
            this.reasonOutros = true;
        } else {
            this.reasonOutros = false;
        }
    }
}
