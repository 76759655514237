import { NgModule } from '@angular/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '../_modules/shared.module';

import { SellerCockpitComponent } from './cockpit/cockpit.component';
import { SellerCommerceComponent } from './produtos-commerce/produtos-commerce.component';
import { SellerCommerceEditAllComponent } from './produtos-commerce/_components/edit-all/edit-all.component';
import { SellerCommerceProductManageComponent } from './produtos-commerce/_components/product-manage/product-manage.component';
import { SellerCommerceProductsListComponent } from './produtos-commerce/_components/products-list/products-list.component';
import { SellerCondicaoFornecimentoComponent } from './_componentes/condicao-fornecimento/condicao-fornecimento.component';
import { SellerConfigurarCondicoesFornecimentoComponent } from './configurar-condicoes-fornecimento/configurar-condicoes-fornecimento.component';
import { SellerDadosCockpitComponent } from './_componentes/dados-cockpit/dados-cockpit.component';
import { SellerDadosCotacaoComponent } from './_componentes/dados-cotacao/dados-cotacao.component';
import { SellerDashboardComponent } from './dashboard/dashboard.component';
import { SellerDocumentacaoComponent } from './documentacao/documentacao.component';
import { SellerPedidosComponent } from './pedidos/pedidos.component';
import { SellerVisaoGeralConfirmarComponent } from './confirmar/confirmar.component';
import { SellerVisaoGeralDetalheComponent } from './detalhe/detalhe.component';
import { SellerVisaoGeralResponderComponent } from './responder/responder.component';
import { SellerVisaoGeralResponderMaior1024Component } from './responder/_componentes/maior1024/maior1024.component';
import { SellerVisaoGeralResponderMenor1024Component } from './responder/_componentes/menor1024/menor1024.component';
import { SellerFotosPedidoComponent } from './_componentes/fotos-pedido/fotos-pedido.component';

import { SellerOrderService } from './_services/order.service';
import { SellerDocumentacaoService } from './documentacao/documentacao.service';
import { SellerDashboardService } from './dashboard/dashboard.service';
import { SellerDadosCotacaoService } from './_componentes/dados-cotacao/dados-cotacao.service';
import { SellerDadosCotacaoNotasFiscaisComponent } from './_componentes/dados-cotacao/_componentes/notas-fiscais/notas-fiscais.component';
import { SellerResponderService } from './responder/responder.service';
import { SellerResponderComponentService } from './responder/_componentes/responder.component.service';
import { SellerSignalRService } from './_services/seller.signalr.service';

import { SellerDadosCotacaoNotasFiscaisResolve } from './_componentes/dados-cotacao/_services/notas-fiscais.resolve';

import { SellerRoutingModule } from './seller.route';
import { getPortuguesePaginatorIntl } from 'app/_utils/get-portuguese-paginator';

@NgModule({
    declarations: [
        SellerCockpitComponent,
        SellerCommerceComponent,
        SellerCommerceEditAllComponent,
        SellerCommerceProductManageComponent,
        SellerCommerceProductsListComponent,
        SellerCondicaoFornecimentoComponent,
        SellerConfigurarCondicoesFornecimentoComponent,
        SellerDashboardComponent,
        SellerDadosCockpitComponent,
        SellerDadosCotacaoComponent,
        SellerDadosCotacaoNotasFiscaisComponent,
        SellerDocumentacaoComponent,
        SellerPedidosComponent,
        SellerVisaoGeralConfirmarComponent,
        SellerVisaoGeralDetalheComponent,
        SellerVisaoGeralResponderComponent,
        SellerVisaoGeralResponderMaior1024Component,
        SellerVisaoGeralResponderMenor1024Component,
        SellerFotosPedidoComponent
    ],
    imports: [
        MatPaginatorModule,
        MatStepperModule,

        SellerRoutingModule,
        SharedModule
    ],
    providers: [
        SellerDocumentacaoService,
        SellerDashboardService,
        SellerDadosCotacaoService,
        SellerOrderService,
        SellerResponderService,
        SellerResponderComponentService,
        SellerSignalRService,

        SellerDadosCotacaoNotasFiscaisResolve,
        {
            provide: MatPaginatorIntl,
            useValue: getPortuguesePaginatorIntl()
        }
    ],
    bootstrap: []
})
export class SellerModule { }
