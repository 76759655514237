import { NgModule } from '@angular/core';

import { AppRoutingModule } from 'app/app-routing.module';

import { SharedModule } from 'app/_modules/shared.module';

import { BuyerPecasCanceladasComponent } from 'app/buyer/_componentes/pecas-canceladas/pecas-canceladas.component';
import { MasterPageComponent } from './master-page.component';
import { UserValidationModalComponent } from './_components/user-validation-modal/user-validation-modal.component';
import { UserDataValidationComponent } from './_components/user-data-validation/user-data-validation.component';
import { UserEmailWhatsappValidationComponent } from './_components/user-email-whatsapp-validation/user-email-whatsapp-validation.component';
import { UserEndValidationComponent } from './_components/user-end-validation/user-end-validation.component';
import { UserValidationService } from './user-validation.service';

@NgModule({
    declarations: [
        BuyerPecasCanceladasComponent,
        MasterPageComponent,
        UserValidationModalComponent,
        UserDataValidationComponent,
        UserEmailWhatsappValidationComponent,
        UserEndValidationComponent,
    ],
    imports: [
        AppRoutingModule,
        SharedModule
    ],
    providers: [ UserValidationService ],
    bootstrap: []
})
export class MasterPageModule { }
