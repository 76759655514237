import { Component, OnInit, ViewChild, } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { AccountService } from 'app/account/account.service';
import { SellerService } from '../../../seller.service';
import { SellerResponderComponentService } from '../responder.component.service';

import { DisplayMessage } from 'app/_utils/generic-form-validation';
import { Globals } from 'app/_models/globals';
import { Peca } from '../../_models/peca';
import { NgScrollbar } from 'ngx-scrollbar';

declare const $: any;
declare const isEmpty: any;

@Component({ selector: 'app-responder-menor1024', templateUrl: './menor1024.component.html', styleUrls: ['./menor1024.component.scss'] })
export class SellerVisaoGeralResponderMenor1024Component implements OnInit
{
    @ViewChild('scrollable') scrollable: NgScrollbar;

    displayMessage: DisplayMessage = {};
    passo_atual: number;
    passo_final: number;
    liberar_proximo: boolean;

    area_faturamento: boolean;

    constructor(public accountService: AccountService, public responderCompService: SellerResponderComponentService, public sellerService: SellerService, private globals: Globals,
        private route: ActivatedRoute) { }

    ngOnInit(): void
    {
        var param_id = this.route.snapshot.params.id;
        this.responderCompService.onInit(param_id);

        this.area_faturamento = false;

        this.liberar_proximo = false;
        this.passo_atual = 1;
        this.passo_final = 1000;
    }

    passo_anterior()
    {
        this.passo_atual--;

        if (this.passo_atual === 1)
            setTimeout(() => this.pecas_selecionar_montar(), 500);

        this.liberar_proximo = this.pecas_checar_liberar_proximo_valido();

        $('.steps-scrollable .ng-scroll-viewport').scrollTop(0);
    }

    passo_proximo()
    {
        if (this.passo_atual === 1)
            this.passo_final = this.pecas_respondidas.length + 2; // Total de peças selecionadas + a tela inicial e a tela de configurações de fornecimento

        this.passo_atual++;
        this.liberar_proximo = this.pecas_checar_liberar_proximo_valido();

        $('.steps-scrollable .ng-scroll-viewport').scrollTop(0);
    }

    pecas_atualizar_tipo_peca(peca)
    {
        // Se o tipo da peça estiver vazia (undefined) ou for do tipo genuína, desabilita o campo de marca
        if (isEmpty(peca.part_type_selected) || peca.part_type_selected === 0 || peca.part_type_selected == 1)
        {
            peca.brand = undefined;
            peca.desabilitar_marca = true;

        }
        else
            peca.desabilitar_marca = false;
    }

    pecas_atualizar_validacao(campo, peca)
    {
        let valido = false;

        switch (campo)
        {
            case 'valor':
                valido = peca.valor_unitario > 0;
                break;
            case 'desconto':
                valido = !isEmpty(peca.desconto);
                break;
        }

        this.displayMessage[campo] = (valido) ? null : this.globals.translate('seller.dashboard.responder.passos.passo-2.erros.' + campo);
        this.liberar_proximo = this.pecas_checar_liberar_proximo_valido();
    }

    pecas_checar_liberar_proximo_valido()
    {
        if (this.pecas_respondidas_nopasso.length === 0)
            return false;

        let peca = this.pecas_respondidas_nopasso[0];

        return peca.valor_unitario > 0 && !isEmpty(peca.desconto) && peca.delivery_time > 0;
    }

    pecas_selecionar_montar()
    {
        $.each(this.pecas_respondidas, function (i, peca)
        {
            $('.peca_notificacao.id_' + peca.item_id).prop("checked", true);
        });

        this.pecas_selecionar_verificar_todas();
        this.liberar_proximo = this.pecas_respondidas.length > 0;
    }

    pecas_selecionar_uma(peca, $event)
    {
        let pecaSelecionada = this.responderCompService.answerQuotation.pecas.find(p => p.item_id === peca.item_id);
        pecaSelecionada.respondida = ($($event.target).is(":checked"));

        let quantity_in_stock_valid = this.pecas_respondidas.every(x => x.quantity_in_stock > 0);

        this.liberar_proximo = this.pecas_respondidas.length > 0 && quantity_in_stock_valid;

        this.pecas_selecionar_verificar_todas();
    }

    pecas_selecionar_todas($event)
    {
        let is_checked = $($event.target).is(":checked");

        this.responderCompService.answerQuotation.pecas.forEach(peca =>
        {
            this.pecas_selecionar_uma(peca, $event);
        });

        $('.peca_notificacao').prop("checked", is_checked);
    }

    pecas_selecionar_verificar_todas()
    {
        if (this.pecas_respondidas.length === 0)
            $('.todas_pecas').prop("checked", false);
        else if (this.pecas_respondidas.length === this.responderCompService.answerQuotation.pecas.length)
            $('.todas_pecas').prop("checked", true);
    }


    get pecas_respondidas()
    {
        return this.responderCompService.answerQuotation.pecas.filter(p => p.respondida);
    }

    get pecas_respondidas_nopasso()
    {
        let posicao = this.passo_atual - 2;

        if (posicao < 0 || posicao > this.pecas_respondidas.length - 1)
            return [];

        return [ this.pecas_respondidas[this.passo_atual - 2] ];
    }

    validateQuantityInStock(peca: Peca) {
        let quantidadeEmEstoqueMaiorQueSolicitado = peca.quantity_in_stock > peca.quantidade;

        if (isEmpty(peca.quantity_in_stock) || quantidadeEmEstoqueMaiorQueSolicitado)
            peca.quantity_in_stock = 0;

        this.liberar_proximo = this.pecas_respondidas.length > 0 && this.pecas_respondidas.every(x => x.quantity_in_stock > 0);
    }
}
