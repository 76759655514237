<main class="manage">
    <form [formGroup]="holdingManageForm" class="form-validate">
        <ng-scrollbar>
            <div class="form-content">
                <div class="plan">
                    <label for="plan">{{ 'support.manage-holdings.manage.label.plan' | translate }}</label>
                    <select class="form-select" formControlName="plan">
                        <option *ngFor="let option of planOptions" [ngValue]="option.value">
                            {{ option.label | translate }}
                        </option>
                    </select>
                </div>
                <div class="paymentType">
                    <label for="paymentType">{{ 'support.manage-holdings.manage.label.payment-type' | translate }}</label>
                    <select class="form-select" formControlName="paymentType" (change)="onPaymentTypeChange()">
                        <option *ngFor="let option of paymentTypeOptions" [ngValue]="option.value">
                            {{ option.label | translate }}
                        </option>
                    </select>
                </div>
                <div class="monthlyFee">
                    <label for="monthlyFee">{{ 'support.manage-holdings.manage.label.monthly-fee' | translate }}</label>
                    <div class="input-with-icon currency monthlyFee">
                        <input type="text" class="form-control" name="monthlyFee" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0.01" max="9999999" maxlength="7" formControlName="monthlyFee"/>
                    </div>
                </div>
                <div class="comissionTax">
                    @if (!isPaymentTypeOrder) {
                        <label for="comissionTax">{{ paymentPercentageLabel | translate }}</label>
                        <div class="input-with-user-icon">
                            <div class="input-with-icon comissionTax" [ngClass]="{ 'percentage': isPaymentTypeComission, 'currency': !isPaymentTypeComission }">
                                <input type="text" class="form-control" name="comissionTax" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="99" maxlength="4" formControlName="comissionTax"/>
                            </div>
                            <button customButton styleType="primary" iconName="user" [isLoading]="false" (click)="repairersComissionManageModal.open()" [isDisabled]="!isPaymentTypeComission"  [tooltip]="'support.manage-holdings.manage.tooltip.custom-comission' | translate"></button>
                        </div>
                    }
                    </div>
                <div class="customCashback">
                    <label for="customCashback">{{ 'support.manage-holdings.manage.label.custom-cashback' | translate }}</label>
                    <select class="form-select" formControlName="customCashback" (change)="onCustomCashbackChange()">
                        <option value="" disabled selected hidden>{{ 'support.manage-holdings.manage.placeholder.select' | translate }}</option>
                        <option [ngValue]="true">{{ 'support.manage-holdings.manage.confirm-options.yes' | translate }}</option>
                        <option [ngValue]="false">{{ 'support.manage-holdings.manage.confirm-options.no' | translate }}</option>
                    </select>
                </div>
                <div class="supplierTax">
                    <label for="supplierTax">{{ 'support.manage-holdings.manage.label.supplier-tax' | translate }}</label>
                    <div class="input-with-icon percentage supplierTax">
                        <input type="text" class="form-control" name="supplierTax" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="1" max="99" maxlength="4" formControlName="supplierTax"/>
                    </div>
                </div>
                <div class="cashbackDefault">
                    <label for="cashbackDefault">{{ 'support.manage-holdings.manage.label.cashback-default' | translate }}</label>
                    <select class="form-select" formControlName="cashbackDefault" (change)="onCashbackDefaultChange()">
                        <option value="" disabled selected hidden>{{ 'support.manage-holdings.manage.placeholder.select' | translate }}</option>
                        <option [ngValue]="true">{{ 'support.manage-holdings.manage.confirm-options.yes' | translate }}</option>
                        <option [ngValue]="false">{{ 'support.manage-holdings.manage.confirm-options.no' | translate }}</option>
                    </select>
                </div>
                <div class="cashbackDefaultValue">
                    <label for="cashbackDefaultValue">{{ 'support.manage-holdings.manage.label.cashback-default-value' | translate }}</label>
                    <div class="input-with-icon percentage cashbackDefaultValue">
                        <input type="text" class="form-control" name="cashbackDefaultValue" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="1" max="10" maxlength="4" formControlName="cashbackDefaultValue" />
                    </div>
                </div>
            </div>
        </ng-scrollbar>
        <footer>
            <button customButton styleType="primary" [outline]="true" iconName="x" label="Cancelar" [isLoading]="false" (click)="close()"></button>
            <button customButton styleType="secondary" [iconName]="!isLoading ? 'check' : null" label="Salvar item" [isLoading]="isLoading" (click)="save()"></button>
        </footer>
    </form>
</main>
<app-holding-custom-comission #templateHoldingCustomComission [holding]="holding"></app-holding-custom-comission>
<app-holding-manage-payment #templateHoldingManagePayment [holding]="holding"></app-holding-manage-payment>
