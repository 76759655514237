<div class="pedidos-vendedor">
    <div class="card card-default">
        <div class="card-header">
            <div class="mb-3 form-group">
                <form class="d-flex flex-row align-items-center flex-wrap">
                    <div class="col-md-2">
                        <div class="input-group">
                            <select class="form-select" [(ngModel)]="filtro_dias" name="filtro_dias">
                                <option value="180">{{'seller.pedidos.filtro.periodo.todos' | translate}}</option>
                                <option value="15" selected>{{'seller.pedidos.filtro.periodo.ultimos-15' | translate}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="input-group search">
                            <span class="input-group-text"><i class="fa fa-search"></i></span>
                            <input type="text" maxlength="100" placeholder="{{'seller.pedidos.filtro.placeholder' | translate}}" class="form-control" name="filtro" [(ngModel)]="filtro" />
                            <button type="submit" class="btn btn-primary input-group-text" (click)="filtrar(0)" [disabled]="(filtro?.length < 2) ? 'disabled' : null">{{ 'seller.pedidos.filtro.botao' | translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="card-body">
            <ng-scrollbar>
                @if (pedidos?.length === 0) {
                    <h3 class="text-center">
                        {{ (primeira_vez ? 'seller.pedidos.mensagens.sem-pedido.inicial' : 'seller.pedidos.mensagens.sem-pedido.filtrado') | translate }}
                    </h3>
                } @else {
                    <div ngbAccordion class="ngbAccordion" [closeOthers]="true">
                        @for (pedido of pedidos; track pedido; let i = $index) {
                            <div ngbAccordionItem>
                                <div ngbAccordionHeader>
                                    <button ngbAccordionButton class="btn btn-sm ml-2" (click)="togglePedido(pedido, i)">
                                        <i class="fa" [ngClass]="i == activeAccordionItemIndex ? 'fa-minus' : 'fa-plus'"></i>
                                        <span>
                                            <i class="float-start media-box-object rounded-circle marca {{pedido?.marca}}"></i>
                                            <span>
                                                {{pedido?.veiculo}}

                                                @if (pedido.haveVehicle) {
                                                    - {{'geral.pedido.codigo' | translate}}{{ pedido?.order_id }}
                                                }
                                            </span>
                                        </span>
                                    </button>
                                    <div class="refresh" (click)="atualizar(pedido?.order_id, 0)">
                                        <i class="fa fa-refresh text-tertiary float-end"></i>
                                    </div>
                                    <ngx-loading [show]="pedido.loading_pedido" [template]="templatePedido"></ngx-loading>
                                </div>
                                <div ngbAccordionCollapse>
                                    <div ngbAccordionBody>
                                        <ng-template>
                                            <hr />
                                            <dados-cotacao (atualizarEvent)="atualizarEvent($event)" [pedido]="pedido" [voltarHome]="false" [transportadoras]="transportadoras"></dados-cotacao>
                                            <ngx-loading [show]="pedido.loading_pedido" [template]="templatePedido"></ngx-loading>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </ng-scrollbar>
        </div>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
<ng-template #templatePedido></ng-template>
