import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Product, productFactory, ProductImage } from '../../_models/product';
import { emptyImageArrayFactory, emptyImageFactory, Image } from '../../../../_componentes/image-picker/_models/image';

import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NotificationService } from 'app/_services/notification.service';
import { Globals } from 'app/_models/globals';
import { SellerCommerceService } from '../../produtos-commerce.service';
import { Categoria } from 'app/buyer/mercado-partsfy/list/_models/Categoria';
import { fileSizeToMB } from 'app/_utils/general-utils';

declare const $: any;
declare const toBase64: any;

@Component({ selector: 'app-commerce-product-manage', templateUrl: './product-manage.component.html', styleUrls: ['./product-manage.component.scss'] })
export class SellerCommerceProductManageComponent {
    @Input() product?: Product = null;
    @Input() partsfyCashback: number = 20;
    @Input() categories: Categoria[] = [];
    @Output() saveProduct = new EventEmitter<Product>();
    @Output() goBackToList = new EventEmitter<null>();
    @ViewChild('templateCropperModal') templateCropperModal: TemplateRef<any>;
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
    @ViewChild('categorySelect') categorySelect: ElementRef;
    @ViewChild('fileInput') fileInput: ElementRef;

    modalCropper: BsModalRef;

    images: Image[];
    imagesHaveChanged: boolean;
    actualImageToAdd: { index: number, imageName: string, contentType: string; order: number; };

    isLoading: boolean = false;
    productManageForm: UntypedFormGroup;
    finalPrice: number = 0;
    cashbackValue: number = 0;

    imageChangedEvent: Event | null = null;
    uploadMaxSizeAccepted: number = 5;

    imageLoading: boolean = false;
    validationMessages: any;

    constructor(private formBuilder: UntypedFormBuilder, private notificationService: NotificationService, private modalService: BsModalService, private globals: Globals, private sellerCommerceService: SellerCommerceService) {
        this.productManageForm = this.formBuilder.group({
            title: [null, [Validators.required, Validators.maxLength(100), Validators.pattern(/[^']/g)]],
            description: [null, Validators.maxLength(100)],
            partNumber: [null, [Validators.required, Validators.maxLength(100)]],
            categoryId: ["", Validators.required],
            price: [0, [Validators.required, Validators.min(0.01), Validators.max(9999.99)]],
            discount: [0, [Validators.required, Validators.min(0), Validators.max(99.99)]],
            cashback: [0, [Validators.required, Validators.min(0), Validators.max(99.99)]],
            isActive: [true, Validators.required],
            holdingId: [0],
            brandId: [0]
        });

        this.images = emptyImageArrayFactory(3);

        this.validationMessages = {
            title: {
                required: this.globals.translate('seller.commerce.products.manage.validation-messages.title'),
                maxlength: this.globals.translate('seller.commerce.products.manage.validation-messages.title-tamanho'),
                pattern: this.globals.translate('seller.commerce.products.manage.validation-messages.title-pattern'),
            },
            partNumber: {
                required: this.globals.translate('seller.commerce.products.manage.validation-messages.part-number'),
                maxlength: this.globals.translate('seller.commerce.products.manage.validation-messages.part-number-tamanho')
            },
            description: {
                maxlength: this.globals.translate('seller.commerce.products.manage.validation-messages.descricao-tamanho')
            },
            categoryId: {
                required: this.globals.translate('seller.commerce.products.manage.validation-messages.category')
            },
            price: {
                required: this.globals.translate('seller.commerce.products.manage.validation-messages.price')
            }
        };
    }

    ngOnInit() {
        if (this.product) {
            this.productManageForm.setValue({
                title: this.product.partName,
                description: this.product.description ?? '',
                partNumber: this.product.partNumber,
                categoryId: this.product.categoryId,
                price: this.product.price,
                discount: this.product.discount,
                cashback: this.product.cashback,
                isActive: this.product.isActive,
                holdingId: this.product.holdingId,
                brandId: this.product.brandId
            });

            this.product.images?.forEach((image, index) => {
                this.sellerCommerceService.getImageData(image.url).then(imageData => {
                    this.images[image.order] = {
                        imageFile: imageData.base64,
                        imageName: image.imageName,
                        contentType: imageData.contentType,
                        isSelected: true,
                        order: image.order
                    };
                });
            });

            this.calculateFinalPrice(this.product.price, this.product.discount);
            this.calculateCashbackValue(this.product.cashback);
        }

        this.productManageForm.get('cashback').valueChanges.subscribe(cashback => this.calculateCashbackValue(cashback));

        this.productManageForm.get('discount').valueChanges.subscribe(discount => {
            this.calculateFinalPrice(this.productManageForm.value.price, discount);
            this.calculateCashbackValue(this.productManageForm.value.cashback);
        });

        this.productManageForm.get('price').valueChanges.subscribe(price => {
            this.calculateFinalPrice(price, this.productManageForm.value.discount);
            this.calculateCashbackValue(this.productManageForm.value.cashback);
        });

        this.imagesHaveChanged = false;
    }

    async addImage($event, index) {
        const file = $event.target.files[0]
        let fileSizeMB = fileSizeToMB(file);

        if (fileSizeMB > this.uploadMaxSizeAccepted) {
            await this.notificationService.showWarning('part-manager.atualizar-estoque.passos.passo-2.mensagens.erro.tamanho', 'part-manager.atualizar-estoque.passos.passo-2.mensagens.erro.titulo');
            return;
        }

        this.imageChangedEvent = $event;
        this.actualImageToAdd = { index, imageName: file.name, contentType: file.type, order: index };

        if (this.imageChangedEvent) {
            this.imageLoading = false;

            let options: ModalOptions = { class: 'custom-modal', backdrop: 'static', keyboard: false };
            this.modalCropper = this.modalService.show(this.templateCropperModal, options);
        }
    }

    calculateFinalPrice(price: number, discount: number) {
        this.finalPrice = price - (price * discount/100);
    }

    calculateCashbackValue(cashback: number) {
        this.cashbackValue = this.finalPrice * cashback/100;
    }

    changeArrayOrder(oldIndex: number, newIndex: number) {
        var tmp = this.images[oldIndex];
        this.images[oldIndex] = this.images[newIndex];
        this.images[newIndex] = tmp;

        this.images[oldIndex].order = oldIndex;
        this.images[newIndex].order = newIndex;
    }

    changeImageOrderToLeft(index: number) {
        this.changeArrayOrder(index, index - 1);
    }

    changeImageOrderToRight(index: number) {
        this.changeArrayOrder(index, index + 1);
    }

    close() {
        this.goBackToList.emit();
    }

    closeCropper() {
        this.imageChangedEvent = null;
        this.actualImageToAdd = null;
        this.modalCropper.hide();
        this.fileInput.nativeElement.value = '';
    }

    confirm() {
        this.imageLoading = true;
        this.imageCropper.crop();
    }

    deletePicture(index: number) {
        this.imagesHaveChanged = true;
        this.images[index] = emptyImageFactory(index);
    }

    fileChangeEvent(event: Event): void {
        this.imageChangedEvent = event;
    }

    imageCropped($event: ImageCroppedEvent) {
        var croppedImage = $event.base64;

        this.images[this.actualImageToAdd.index] = {
            imageFile: croppedImage.indexOf(',') > -1 ? croppedImage.split(',')[1] : croppedImage,
            imageName: this.actualImageToAdd.imageName,
            contentType: this.actualImageToAdd.contentType,
            isSelected: true,
            order: this.actualImageToAdd.index
        }

        this.closeCropper();

        this.imageLoading = false;
        this.imagesHaveChanged = true;
    }

    openFileSelection($event) {
        $($event.target).next().click()
    }

    save() {
        if (!this.productManageForm.touched && !this.imagesHaveChanged) {
            this.close();
            return;
        }

        if (!this.isFormValid())
            return;

        this.isLoading = true;

        const formValue = this.productManageForm.value
        let images: ProductImage[] = []

        this.images.forEach(image => {
            if (image.isSelected)
                images.push({ imageName: image.imageName, imageB64: image.imageFile, contentType: image.contentType, order: images.length });
        });

        const categoryName = this.categories.find(c => c.categoryId == formValue.categoryId)!.name;
        const product = productFactory(this.product?.productId, formValue.partNumber, formValue.title, formValue.description, formValue.categoryId, categoryName, formValue.price, formValue.discount, formValue.cashback, images, formValue.isActive, formValue.holdingId, formValue.brandId)

        try {
            this.saveProduct.emit(product);
        } finally {
            this.isLoading = false;
        }
    }

    isFormValid() {
        Object.keys(this.productManageForm.controls).forEach(field => {
            const control = this.productManageForm.get(field);
            control?.markAsTouched({ onlySelf: true });
        });

        if (this.productManageForm.valid)
            return true;

        Object.keys(this.validationMessages).forEach(field => {
            const control = this.productManageForm.get(field);
            const errorMessages = this.validationMessages[field];

            if (control?.invalid) {
                Object.keys(errorMessages).forEach(errorKey => {
                    if (control.errors?.[errorKey]) {
                        this.notificationService.showErrorToastr(errorMessages[errorKey]);
                    }
                });
            }
        });

        return false;
    }
}
