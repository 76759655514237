<div class="col-md-12 dados-cotacao {{ accountService.obterCssInIframe() }}">
    <div class="row only-print">
        {{pedido?.marca}} - {{pedido?.veiculo}}
    </div>
    <ng-scrollbar height="auto" class="{{ accountService.obterCssInIframe() }}">
        <div class="row" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
            <div class="col-md-12">
                <div id="orcamento-solicitado" class="card card-primary orcamento-solicitado">
                    <div class="print-vendedor card-header painelcabecalho">
                        <b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.solicitado-pedido' | translate}}</b>
                        <span class="namecompr">{{pedido?.comprador_empresa}}</span> - <small><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.cod-orcamento' | translate}}:</b>  #{{pedido?.order_id}}</small>
                        <br class="only-print" />
                        @if (pedido?.had_cashback) {
                            <span class="cashback text-success"><i class="fa fa-usd"></i> {{ 'seller.dashboard.detalhes.informacoes.cabecalho.cashback-pedido' | translate}} <i class="fa fa-usd"></i></span>
                        }
                    </div>
                    <div class="card-body font12">
                        <div class="info-cotacao col-md-12">
                            <div class="col-md-6">
                                <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.cnpj' | translate}}:</b> {{pedido?.repairer_cnpj_formatado}}</span>
                                <br />
                                <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.telefone' | translate}}:</b> {{pedido?.buyer_phone_radio}}</span>
                                <br />
                                <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.endereco' | translate}}:</b> {{pedido?.repairer_address}}</span>
                            </div>
                            @if (pedido && pedido.veiculo) {
                                <div class="col-md-6">
                                    @if (pedido.haveVehicle) {
                                        <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.chassi' | translate}}:</b> {{pedido?.chassi}}</span>
                                        <br/>
                                    }
                                    <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.forma-pagamento' | translate}}:</b> {{pedido?.forma_pagamento}}</span>
                                    <br />
                                    <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.data-pedido' | translate}}:</b> {{pedido?.data_pedido}}</span>
                                </div>
                            }
                        </div>
                        <div class="table-responsive col-md-12 lista-pecas">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>{{ 'seller.dashboard.detalhes.tabela.quantidade' | translate}}</th>
                                        <th>{{ 'seller.dashboard.detalhes.tabela.peca' | translate}}</th>
                                        <th>{{ 'seller.dashboard.detalhes.tabela.marca' | translate}}</th>
                                        <th>{{ 'seller.dashboard.detalhes.tabela.status' | translate}}</th>
                                        <th>{{ 'seller.dashboard.detalhes.tabela.prazo.label' | translate}} <i class="fa fa-info-circle no-print" tooltip="{{ 'seller.dashboard.detalhes.tabela.prazo.tooltip' | translate}}"></i></th>
                                        <th>{{ 'seller.dashboard.detalhes.tabela.valor' | translate}}</th>
                                        <th>{{ 'seller.dashboard.detalhes.tabela.desconto' | translate}}</th>
                                        <th>{{ 'seller.dashboard.detalhes.tabela.total' | translate}}</th>
                                        @if (pedido.alguemParaAcao) {
                                            <th>
                                                <div class="form-check c-checkbox needsclick checkboxcot">
                                                    <label class="needsclick">
                                                        <input type="checkbox" class="needsclick" (click)="acaoSelecionarPeca_Todas($event)">
                                                        <span class="fa fa-check"></span>
                                                    </label>
                                                </div>
                                            </th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    @for (peca of pedido?.pecas; track peca) {
                                        <tr>
                                            <td>{{peca.quantidade}}</td>
                                            <td class="ellipsis4" ellipsis>{{peca.peca}}</td>
                                            <td class="coluna-marca">
                                                @if (peca.is_used) {
                                                    <i class="fa fa-exclamation-circle float-end"  tooltip="{{ 'elemento.tipo-pecas.usada' | translate}}"></i>
                                                }
                                                <div>
                                                    @if (peca.part_type != 1) {
                                                        <div class="code">
                                                            <div class="truncate">{{peca.brand}}</div>
                                                        </div>
                                                    }
                                                    <div class="truncate name">{{peca.tipo_peca}}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <i class="circle circle-{{sellerService.obterStatusClassName(peca.status)}} circle-lg text-start icone-plus no-print"></i>
                                                {{peca.status_peca}}
                                                @if (peca.status >= StatusIdEnum.CotacaoCanceladaComprador && peca.rejected_information) {
                                                    <i class="fa fa-exclamation-circle text-warning float-end"    tooltip="{{ 'buyer.dados-pedido.itens.pecas.situacao.tooltip-cancelamento' | translate}}: {{peca.rejected_information}}"></i>
                                                }
                                            </td>
                                            <td class="text-center">{{peca.previsao_entrega}}</td>
                                            <td class="text-end">{{peca.valor_unitario | currency:'R$ '}}</td>
                                            <td class="text-end">{{peca.desconto | currency:' '}}%</td>
                                            <td class="text-end">{{sellerService.obterTotalPeca(peca) | currency:'R$ '}}</td>
                                            @if (pedido.alguemParaAcao) {
                                                <td>
                                                    @if (peca.status >= StatusIdEnum.Confirmado && peca.status <= StatusIdEnum.AguardandoDevolucao && peca.status != StatusIdEnum.Entregue) {
                                                        <div class="form-check c-checkbox needsclick checkboxcot">
                                                            <label class="needsclick">
                                                                <input type="checkbox" class="needsclick" [(ngModel)]="peca.acao" (click)="acaoSelecionarPeca(peca, $event)">
                                                                <span class="fa fa-check"></span>
                                                            </label>
                                                        </div>
                                                    }
                                                </td>
                                            }
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer text-end">
                        <span><b>{{ 'seller.dashboard.detalhes.tabela.rodape.total' | translate}}:</b> {{ pedido?.total_com_frete | currency:'R$ ' }}</span>
                        <br />
                        <small>
                            {{ 'seller.dashboard.detalhes.tabela.rodape.frete.label' | translate}}:
                            {{ pedido?.frete_formatado }}
                            / {{ 'seller.dashboard.detalhes.tabela.rodape.total-pecas' | translate}}: {{ pedido?.total | currency:'R$ ' }}
                        </small>
                        <br />
                        <div class="botoes">
                            @if (!voltarHome && !pedido.todasCanceladas && pedido && !pedido.statusNaoConfirmado) {
                                <button class="btn btn-light nota-fiscal" [ngClass]="{ 'text-danger': !pedido.tem_nota_fiscal }" (click)="goToInconsistencias()">
                                    @if (pedido.tem_nota_fiscal) {
                                        <i class="fa fa-folder-open"></i>
                                    } @else {
                                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    }
                                    &nbsp;{{ 'seller.dashboard.detalhes.botao.notas-fiscais' | translate}}
                                </button>
                            }

                            @if (pedido?.fotos?.length > 0) {
                                <button type="button" class="btn btn-light" (click)="modal_fotos_abrir()"> {{'seller.dashboard.detalhes.botao.fotos' | translate}} </button>
                            }

                            @if (pedido.alguemParaAcao) {
                                <button type="button" class="btn btn-outline-danger btn-sm ml-sm" (click)="modal_rejeitar_abrir()" [disabled]="!pedido.alguemSelecionado ? 'disabled' : null">{{ 'seller.dashboard.detalhes.botao.cancelar-entrega' | translate}}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-scrollbar>
</div>

<ng-template #templateFotos>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'seller.dashboard.detalhes.fotos.titulo' | translate}}</h4>
        <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="modal_fotos_fechar();"></button>
    </div>
    <div class="col-md-12 fotos mt-xl pr0">
        <div class="col-md-8 offset-md-2 carousel-card-body">
            @if (pedido?.fotos) {
                <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">
                    @for (image of pedido?.fotos; track image) {
                        <ng-template ngbSlide>
                            <div class="picsum-img-wrapper">
                                <img class="image-to-zoom" [src]="image" alt="Foto Veículo" (click)="zoomImage(image)">
                            </div>
                        </ng-template>
                    }
                </ngb-carousel>
            }
        </div>
    </div>
    <div class="modal-footer">
        <button id="closeModal" type="button" class="btn btn-light" (click)="modal_fotos_fechar();">{{ 'elemento.generico-botao.fechar' | translate}}</button>
    </div>
</ng-template>

<ng-template #templateCotacao></ng-template>

<ng-template #templateRejeitarPeca>
    <div class="modal-header">
        <div class="d-flex flex-column">
            <h4 class="modal-title">{{ 'seller.dashboard.rejeitar-peca.titulo' | translate}}</h4>
            <span class="modal-subtitle">{{ 'seller.dashboard.rejeitar-peca.subtitulo' | translate}}<span class="dados-veiculo">{{pedido?.veiculo}}</span></span>
        </div>
        <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="modal_rejeitar_fechar();"></button>
    </div>
    <div class="modal-body modalscroll">
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3 form-group">
                    <label>{{ 'seller.dashboard.rejeitar-peca.legenda' | translate}}</label>
                    <select (change)="updateTextArea($event)" name="reason" class="form-select m-b">
                        @for (reason of motivosRejeitar; track reason) {
                            <option value="{{reason.id}} - {{reason.name}}">{{reason.description}}</option>
                        }
                    </select>
                </div>
            </div>
        </div>
        <div class="row" style="height: 113px;">
            <div class="col-lg-12">
                <div class="mb-3 form-group">
                    <textarea [disabled]="reasonOutros ? null : true" name="comment" rows="4" placeholder="{{'elemento.generico-input.placeholder' | translate}}" class="form-control comment"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button id="closeModal" type="button" data-dismiss="modal" class="btn btn-light" (click)="modal_rejeitar_fechar();">{{ 'seller.dashboard.rejeitar-peca.botao.cancelar' | translate}}</button>
        <button id="btnSalvar" type="button" class="btn btn-primary" (click)="rejeitarEntrega(0)">{{ 'seller.dashboard.rejeitar-peca.botao.rejeitar' | translate}}</button>
    </div>
    <ngx-ui-loader [loaderId]="'loader-rejeitar'"></ngx-ui-loader>
</ng-template>
